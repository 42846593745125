import { useForm, SubmitHandler } from "react-hook-form"
import validator from "validator"
import { Button, CircularProgress, FormControl, FormHelperText, Grid, InputAdornment, OutlinedInput, TextField, Typography, useTheme } from "@mui/material"
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import { Validators } from "utils/validators";
import { CEPMaskCustom, CPFMaskCustom, CellphoneMaskCustom } from "components/mask";
import { AuthService } from "service/auth";
import { LoadingButton } from "@mui/lab";
import { Moment } from "moment";
import useSocket from "service/socket";
import useAuth from "context/auth";
import { toast } from "react-toastify";

const LoginPage = () => {

    const { socket } = useSocket();
    const { Authenticate } = useAuth();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<{ cpf: string, password: string }>()


    const [loading, setLoading] = useState(false)


    const handleSubmitLogin = ({ cpf, password }: { cpf: string, password: string }) => {
        setLoading(true)
        AuthService.login(cpf.replace(/\D/g, ""), password)
            .then(({ data }) => {
                if (data?.id) {
                    Authenticate(data)
                }
            })
            .catch((err) => {
                toast(err?.response?.data?.message ?? "Error ao realizar login", {
                    type: 'error'
                })
            })
            .finally(() => setLoading(false))
    }

    return (
        <Grid mt={5}>
            <Typography
                fontWeight="bold"
                variant="h2"
                textAlign="center">

            </Typography>
            <Grid container mt={5} gap={2}>
                <Typography
                    fontWeight="bold"
                    variant="h5"
                >
                    Informe seu CPF
                </Typography>
                <Grid container>
                    <OutlinedInput
                        error={!!errors.cpf}
                        placeholder="000.000.000-00"
                        inputComponent={CPFMaskCustom}
                        disabled={loading}
                        fullWidth
                        {...register("cpf", {
                            required: true,
                            // validate: (value) => {
                            //     if (!Validators.validateCPF(value)) {
                            //         return "Cpf invalido!"
                            //     }
                            // }
                        })}
                    />
                    <FormHelperText sx={{ color: 'red' }}>{errors.cpf?.message as string}</FormHelperText>
                </Grid>
                <Typography
                    fontWeight="bold"
                    variant="h5"
                >
                    Informe a senha
                </Typography>
                <Grid container>
                    <OutlinedInput
                        error={!!errors.password}
                        placeholder="●●●●●●"
                        type="password"
                        disabled={loading}
                        fullWidth
                        {...register("password", {
                            required: true,
                            minLength: {
                                value: 4,
                                message: 'Senha deve ter no mínimo 4 caracteres'
                            }
                        })}
                    />
                    <FormHelperText sx={{ color: 'red' }}>{errors.password?.message as string}</FormHelperText>
                </Grid>
                <LoadingButton
                        loading={loading}
                        disabled={loading}
                        variant="contained"
                        fullWidth
                        sx={{ mt: 2 }}
                        onClick={handleSubmit(handleSubmitLogin)}
                    >
                        Entrar
                    </LoadingButton>
            </Grid>
        </Grid>
    )
}

export default LoginPage;