import { LoadingButton } from "@mui/lab";
import { Autocomplete, Card, CardContent, Collapse, Container, FormControl, Grid, TableBody, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import ButtonBackToTop from "components/backToTop";
import { formatCPF } from "components/mask";
import TableView from "components/table";
import moment, { Moment } from "moment";
import { useEffect, useRef, useState } from "react";
import { ReportsService } from "service/reports";

interface IReportExpress {
    bets: number
    numbers: number
    numbersOnBetsAVG: number
    paid: IPaid
    totalInPrizes: number
    totalInPrizesWithoutJoker: number
    prizes: Array<{
        total: number
        value: number
        fundId: number
    }>
    jokers: {
        total: number
        value: number
    }
    duplicatedBets: number
    clients: Array<IClients>
    funds: Array<{
        id: number
        code: string
        legible: string
        fromDeposit: number
        fromWinners: number
        total: number
        totalAvailable: number
        prizeValue: number
    }>
}

interface IPaid {
    deposit: number
    coin: number
    prizes: number
    total: number
}

interface IClients {
    id: number
    name: string
    cpf: string
    prizes: number
    phone: string
}

export const ReportExpress = () => {
    const [report, setReport] = useState<IReportExpress>()
    const [startAt, setStartAt] = useState(moment())
    const [endAt, setEndAt] = useState(moment())
    const [clients, setClients] = useState<IClients[]>([])

    const [loading, setLoading] = useState(false)
    const [loadingExport, setLoadingExport] = useState(false)
    const clientResult = (report?.totalInPrizes ?? 0) - (report?.paid?.total ?? 0)
    const clientResultPercent = (((clientResult) / (report?.paid?.total ?? 0)) * 100)
    const totalInPrizesPercent = (((report?.totalInPrizes ?? 0) / (report?.paid?.total ?? 0)) * 100)
    const containerRef = useRef(null)

    type PaidKeys = 'deposit' | 'coin' | 'prizes' | 'total'


    const getReport = (download: boolean = false, client?: number) => {
        if (download) {
            setLoadingExport(true)
        } else {
            setLoading(true)
        }
        ReportsService.getInstReport({
            "end": moment(endAt).format("YYYY-MM-DD"),
            "begin": moment(startAt).format("YYYY-MM-DD"),
            ...((clients.length > 0 && !client) && {
                "clients": clients.map((client) => client.id)
            }),
            ...(client && {
                "clients": [client]
            }),
            ...(download && {
                export: true
            })
        }).then(({ data }) => {
            if (download) {
                window.open(data.url, '_blank');
            } else {
                setReport(data)
            }
        }).finally(() => {
            if (download) {
                setLoadingExport(false)
            } else {
                setLoading(false)
            }
        })
    }


    const toCurrency = (value?: number) => {
        return (value ?? 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    useEffect(() => {
        getReport()
    }, [])

    const paymentTranslate = (value: PaidKeys) => {
        switch (value) {
            case "coin":
                return "Bônus de consolação"
            case "deposit":
                return "De Depósito"
            case "prizes":
                return "Valor de saque"
            case "total":
                return "Total"
            default:
                return "Não registrado..."
        }
    }

    const LocalCard = ({
        description,
        title,
        negative
    }: {
        title: string,
        description: any,
        negative?: boolean
    }) => {
        return (
            <Card sx={{ width: 275, height: 243 }}>
                <CardContent
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        width: '100%',
                        height: 243,
                        padding: 0,
                    }}
                >
                    <Typography
                        gutterBottom
                        sx={{
                            color: 'text.primary',
                            fontSize: 18,
                            fontWeight: '600',
                            padding: '0px 20px',
                            textAlign: 'center'
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography sx={{ fontSize: 30, fontWeight: '700', ...(negative && { color: 'red' }) }}>
                        {description}
                    </Typography>
                </CardContent>
            </Card>
        )
    }

    const renderPlural = (value: string, condition: boolean) => {
        if (condition) {
            return value + 's'
        }
        return value
    }


    return (
        <Grid
            container
            p={4}
            ref={containerRef}
            style={{
                height: '100vh',
                overflowY: 'auto'
            }}
        >
            <Container>
                <Grid container alignItems="center" justifyContent={'space-between'}>
                    <Typography variant="h2">
                        Relatórios - Sorteio rápido
                    </Typography>
                </Grid>

                <Grid container mt={4} gap={2}>
                    <FormControl>
                        <DesktopDatePicker
                            format="DD/MM/YYYY"
                            label="Data de início"
                            disableFuture
                            value={startAt}
                            onChange={(event) => {
                                setStartAt(event as Moment)
                            }}
                        />
                    </FormControl>

                    <FormControl>
                        <DesktopDatePicker
                            format="DD/MM/YYYY"
                            label="Data de fim"
                            disableFuture
                            value={endAt}
                            onChange={(event) => {
                                setEndAt(event as Moment)
                            }}
                        />
                    </FormControl>

                    <FormControl sx={{
                        minWidth: '300px'
                    }}>
                        <Autocomplete
                            options={(report?.clients ?? [])?.sort((a, b) => b.prizes - a.prizes)}
                            id="clients"
                            blurOnSelect
                            fullWidth
                            multiple
                            value={clients}
                            onChange={(_, newValue: IClients[]) => {
                                setClients(newValue);
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option: IClients) => `${formatCPF(option.cpf)} - ${option.name} - ${toCurrency(option.prizes)}`}
                            noOptionsText={
                                report?.clients?.length === 0
                                    ? "Ainda não há clientes para a data estipulada ou a requisição ainda não foi finalizada..."
                                    : ""
                            }
                            renderInput={(params) => (
                                <TextField {...params} label="Selecionar cliente" />
                            )}
                        />
                    </FormControl>

                    <LoadingButton sx={{
                        minWidth: '150px'
                    }} variant="contained" loading={loading} disabled={loading} onClick={() => getReport()}>Buscar</LoadingButton>

                    {/* <LoadingButton sx={{
                        minWidth: '150px'
                    }} variant="outlined" loading={loadingExport} disabled={loadingExport} onClick={() => getReport(true)}>Baixar planilha</LoadingButton> */}


                </Grid>

                <Collapse in={!loading}>
                    <Grid container mt={4} gap={4}>
                        <Typography variant="h3" mb={-2}>Fundos</Typography>
                        <Grid container display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"flex-start"} gap={2}>
                            {report?.funds?.sort((a, b) => a.id - b.id)?.map((fund) => {
                                return (
                                    <Card sx={{ width: 275, height: 243 }} key={'fund-' + fund.id}>
                                        <CardContent>
                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 18, fontWeight: '600' }}>
                                                {fund.legible}
                                            </Typography>
                                            <Typography variant="h5" component="div">
                                                {toCurrency(fund.total)}
                                            </Typography>
                                            <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>total</Typography>

                                            <Typography variant="h5" component="div">
                                                {toCurrency(fund.prizeValue)}
                                            </Typography>
                                            <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>valor unitário</Typography>

                                            <Typography variant="h5" component="div">
                                                {fund.totalAvailable}
                                            </Typography>
                                            <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>quantidade disponível</Typography>

                                        </CardContent>
                                    </Card>
                                )
                            })}
                            <LocalCard
                                title="Fundo total"
                                description={toCurrency(report?.funds?.reduce((acc, fund) => acc + fund.total, 0))}
                                key={'fund-total'}
                            />
                        </Grid>

                        <Typography variant="h3" mb={-2}>Apostas</Typography>
                        <Grid container display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"flex-start"} gap={2}>

                            <LocalCard
                                title="Quantidade de jogos"
                                description={report?.bets}
                                key={'bets'}
                            />

                            <LocalCard
                                title="Quantidade Cara/Coroa x2"
                                description={report?.duplicatedBets}
                                key={'duplicatedBets'}
                            />

                            <LocalCard
                                title="Quantidade de apostas"
                                description={report?.numbers}
                                key={'numbers'}
                            />

                            <LocalCard
                                title="Média de palpites por jogo"
                                description={report?.numbersOnBetsAVG}
                                key={'numbersOnBetsAVG'}
                            />

                            <LocalCard
                                title="Formas de pagamento"
                                description={
                                    <Grid
                                        width={"100%"}
                                        display={"flex"}
                                        flexWrap={"wrap"}
                                        flexDirection={"row"}
                                        justifyContent={"left"}
                                        gap={2}
                                        padding={"0px 35px"}
                                    >
                                        {
                                            Object.keys(report?.paid ?? {})?.map((item) => {
                                                const value = report?.paid[item as PaidKeys]
                                                return (
                                                    <Grid
                                                        display={"flex"}
                                                        flexDirection={"column"}
                                                        justifyContent={"center"}
                                                    >
                                                        <Typography>{paymentTranslate(item as PaidKeys)}</Typography>
                                                        <Typography fontSize={16} fontWeight={"700"}>{toCurrency(value)}</Typography>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                }
                                key={'paid'}
                            />

                        </Grid>

                        <Typography variant="h3" mb={-2}>Prêmios</Typography>
                        <Grid container display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"flex-start"} gap={2}>

                            {report?.prizes.map((prize) => {
                                const legible = report?.funds.find((item) => item.id === prize.fundId)?.legible
                                return (
                                    <Card sx={{ width: 275, height: 243 }} key={'prizes-' + prize.fundId}>
                                        <CardContent>
                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 18, fontWeight: '600' }}>
                                                {legible}
                                            </Typography>
                                            <Typography variant="h5" component="div">
                                                {toCurrency(prize.value)}
                                            </Typography>
                                            <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>total</Typography>

                                            <Typography variant="h5" component="div">
                                                {prize.total}
                                            </Typography>
                                            <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>quantidade</Typography>
                                        </CardContent>
                                    </Card>
                                )
                            })}

                            <Card sx={{ width: 275, height: 243 }} key={'joker-prize'}>
                                <CardContent>
                                    <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 18, fontWeight: '600' }}>
                                        Bônus de consolação
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        {toCurrency(report?.jokers?.value)}
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>total</Typography>

                                    <Typography variant="h5" component="div">
                                        {report?.jokers.total}
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>quantidade</Typography>
                                </CardContent>
                            </Card>

                            <LocalCard
                                title="Total em prêmio sem prêmio de consolação"
                                description={toCurrency(report?.totalInPrizesWithoutJoker)}
                                key={'totalInPrizesWithoutJoker'}
                            />

                            <LocalCard
                                title="Total em prêmios"
                                description={toCurrency(report?.totalInPrizes)}
                                key={'totalInPrizes'}
                            />

                            <LocalCard
                                title="Total em prêmios em %"
                                description={totalInPrizesPercent.toFixed(2) + '%'}
                                key={'totalInPrizesPercent'}
                                negative={totalInPrizesPercent < 0}
                            />

                            <LocalCard
                                title={`Resultado ${renderPlural('do', clients?.length !== 1)} ${renderPlural('cliente', clients?.length !== 1)}`}
                                description={toCurrency(clientResult)}
                                key={'result-client'}
                                negative={clientResult < 0}
                            />

                            <LocalCard
                                title={`Resultado ${renderPlural('do', clients?.length !== 1)} ${renderPlural('cliente', clients?.length !== 1)} em %`}
                                description={clientResultPercent.toFixed(2) + '%'}
                                key={'result-client-percent'}
                                negative={clientResultPercent < 0}
                            />

                        </Grid>

                        <Typography variant="h3" mb={-2}>Clientes ({(report?.clients ?? []).length})</Typography>
                        <Grid container>
                            <TableView
                                align='left'
                                header={['ID', 'CPF', 'NOME', 'WHATSAPP', 'TOTAL EM PRÊMIOS']}
                            >
                                <TableBody>
                                    {(report?.clients ?? [])?.sort((a, b) => b.prizes - a.prizes).map((client) => {
                                        const {
                                            cpf,
                                            id,
                                            name,
                                            prizes,
                                            phone
                                        } = client
                                        return (
                                            <TableRow
                                                onClick={() => {
                                                    if ((report?.clients ?? []).length > 1) {
                                                        setClients([client])
                                                        getReport(false, id)
                                                    }
                                                }}
                                                sx={{
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(0, 0, 0, 0.1)'
                                                    }
                                                }}
                                            >
                                                <TableCell align="left">{id}</TableCell>
                                                <TableCell align="left">{formatCPF(cpf)}</TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold' }}>{name}</TableCell>
                                                <TableCell align="left">{phone}</TableCell>
                                                <TableCell align="left">{toCurrency(prizes)}</TableCell>
                                            </TableRow>
                                        )
                                    })}

                                </TableBody>
                            </TableView>
                        </Grid>
                    </Grid>
                </Collapse>

            </Container>
            <ButtonBackToTop
                containerRef={containerRef}
            />
        </Grid>
    )
}