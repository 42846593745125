import api from "../api"

export class PrizeDrawsService {
    static list({ status, kind, prizedrawDate, page, limit }: { status?: number, kind?: number, prizedrawDate?: string, page?: number, limit?: number }) {
        return api.get(`/prize-draws`, {
            params: {
                status,
                kind, 
                prizedrawDate,
                page,
                limit
            }
        })
    }

    static uploadLinkBanner(data: { contentType: string, filename: string }) {
        return api.post('/prize-draws/banner-upload-link', data)
    }

    static uploadLinkVideo(data: { contentType: string, filename: string }) {
        return api.post('/prize-draws/video-upload-link', data)
    }

    static updateVideo(data: { id: number, fileId: number }) {
        return api.put('/prize-draws/video/' + data.id, data)
    }

    static updateVideoStorage(data: { number: string, fileId: number }) {
        return api.put('/prize-draws/video/', data)
    }

    static createNewPrizeDraw(data: any){
        return api.post('/prize-draws', data)
    }

    static updatePrizeDraw(data: any){
        return api.put('/prize-draws', data)
    }

    static updatePrize(data: any){
        return api.put('/prize-draws', data)
    }
   
    static ClosePrizeDraw(id: string | number){
        return api.patch(`/prize-draws/close/${id}`)
    }

    static Report(id: number){
        return api.get(`/prize-draws/admin-report/${id}`)
    }

    static downloadCorrespondent(){
        return api.get(`/users/all-sellers-sheet`)
    }
    
    static downloadClient(){
        return api.get(`/clients/all-clients-sheet`)
    }

    static exportnumber(prizeDrawId: number){
        return api.get(`/bets/export-numbers/${prizeDrawId}`)
    }

    static downloadPDF(prizeDrawId: number){
        return api.get(`/prize-draws/pdf-result/${prizeDrawId}`)
    }

    static downloadAllPrizeDraws(){
        return api.get(`/prize-draws/all-sheet`)
    }

}