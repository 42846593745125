import AuthLayout from "layout/auth";
import LoginPage from "pages/auth/login";
import { Navigate, Outlet, createBrowserRouter } from "react-router-dom"

const PublicRouters = [
    {
        element: <AuthLayout />,
        path: "",
        children: [
            {
                element: <Navigate to="login" />,
                path: "/",
            },
            {
                element: <Navigate to="login" />,
                path: "/*",
            },
            {
                element: <LoginPage />,
                path: "/login",
            }
        ]
    },
]

export default PublicRouters;