import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Autocomplete, Card, CardContent, DialogTitle, Divider, FormControl, FormHelperText, FormLabel, Grid, InputAdornment, MenuItem, OutlinedInput, Select, Switch, TextField, Typography, useTheme } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { PrizeDrawsService } from 'service/concurso';
import { IChangePolicy, ICreatePolicy, IPoliciesProps, IPolicyOfUser, PrizeDrawsProps, UserGeralProps } from 'types';
import { toast } from 'react-toastify';
import { CPFMaskCustom, CellphoneMaskCustom, PercentMaskInput, formatCPF, formatPhone } from 'components/mask';
import { useForm } from 'react-hook-form';
import { Validators } from 'utils/validators';
import { AuthService } from 'service/auth';
import { round } from 'utils/functions';

interface HandlePoliciesProps {
    open: boolean,
    handleClose: (closed?: boolean) => void,
    policiesList: IPoliciesProps[],
    user: UserGeralProps
}

export default function HandlePolicies({ open, handleClose, policiesList, user }: HandlePoliciesProps) {

    const theme = useTheme();

    const [loading, setLoading] = useState(false);

    const [policies, setPolicies] = useState<IPoliciesProps[]>([]);

    const [unchangedPolicies, setUnchangedPolicies] = useState<IPoliciesProps[]>([]);

    const [policyOfUser, setPolicyOfUser] = useState<IPolicyOfUser>()

    const handleCloseChange = () => {
        handleClose()
        setPolicies([])
        setUnchangedPolicies([])
    }

    const levelLabel = (level: number) => {
        let label = "";


        switch (true) {
            case (level === 0):
                label = "Venda própria"
                break;

            case (level === 1):
                label = "Indicação direta"
                break;

            case (level === 2):
                label = "Indicação indireta 1° Grau"
                break;

            case (level === 3):
                label = "Indicação indireta 2° Grau"
                break;

            case (level === 4):
                label = "Indicação indireta 3° Grau"
                break;

            case (level === 5):
                label = "Indicação indireta 4° Grau"
                break;

            default:
                label = "Não identificado"
                break;
        }


        return label;
    }

    useEffect(() => {
        const newPolicies = policiesList.map((policy) => {
            return {
                ...policy,
                percentage: round(policy.percentage * 100, 4)
            }
        })
        setPolicies(newPolicies)
        setUnchangedPolicies(newPolicies)

        const _p = newPolicies.find((item) => item.role === 20)
        if (_p) {
            setPolicyOfUser(_p)
        } else {
            setPolicyOfUser({
                active: false,
                description: "",
                percentage: 0
            })
        }
    }, [])

    const saveChanges = async () => {
        setLoading(true)
        let _policites = policies.filter((item) => item.role === 10)

        let existPolicyOfUser = policies.find((item) => item.role === 20)
        if (existPolicyOfUser) {
            let unChangedPolicy = unchangedPolicies.find((item) => item.id === existPolicyOfUser?.id) as IPoliciesProps
            let hasChanged = false;

            if (policyOfUser) {
                for (let key of Object.keys(policyOfUser ?? {})) {
                    if (unChangedPolicy[key as keyof IPolicyOfUser] !== policyOfUser[key as keyof IPolicyOfUser]) {
                        hasChanged = true;
                    }
                }
            }

            if (hasChanged) {
                _policites.push(policyOfUser as IPoliciesProps)
            }
        } else {
            let data: ICreatePolicy = {
                description: policyOfUser?.description ?? "",
                generalId: user.id,
                role: 20,
                level: 0,
                percentage: round(parseFloat(policyOfUser?.percentage as any) / 100, 4),
            }
            console.log(data)
            await AuthService.createPolicy(data)
        }
        for (let policy of _policites) {
            let hasChanged = false;

            let unChangedPolicy = unchangedPolicies.find((item) => item.id === policy.id) as IPoliciesProps

            if (unChangedPolicy) {
                for (let key of Object.keys(policy)) {
                    if (unChangedPolicy[key as keyof IPoliciesProps] !== policy[key as keyof IPoliciesProps]) {
                        hasChanged = true;
                    }
                }
            }

            if (hasChanged) {
                let data: IChangePolicy = {
                    active: policy.active,
                    description: policy.description ?? null,
                    id: policy.id,
                    percentage: round(parseFloat(policy.percentage as any) / 100, 4)
                }
                await AuthService.changePolicies(data);
            }
        }
        setLoading(false)
        setUnchangedPolicies(policies)
        toast("Alterações salvas", { type: "success" })
        handleClose()
    }

    return (
        <Dialog
            open={open}
            // onClose={handleCloseChange}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { width: '100%' } }}
            maxWidth="lg"
        >
            <DialogTitle>
                Gerenciar comissões
            </DialogTitle>
            <DialogContent>
                <Grid mt={2}>
                    <Typography><strong>Nome:</strong> {user?.name}</Typography>
                    <Typography><strong>CPF:</strong> {formatCPF(user?.cpf)}</Typography>
                    <Typography><strong>WhatsApp:</strong> {formatPhone(user?.personalData?.phone)}</Typography>

                </Grid>
                <Divider sx={{
                    margin: '20px 0px'
                }} />
                <Grid mb={2} pl={2.5}>
                    <Typography variant='h3'>Políticas dos revendedores</Typography>
                </Grid>
                <Grid
                    container
                    display={"flex"}
                    flexDirection={"row"}
                    gap={2}
                    flexWrap={"wrap"}
                    justifyContent={"space-around"}>
                    {policies.sort((a, b) => a.level - b.level).filter((item) => item.role === 10).map((item) => {
                        return (
                            <Card
                                key={item.id}
                                sx={{ width: 300, padding: 2 }}>
                                <CardContent sx={{
                                    display: "flex",
                                    gap: 2, flexDirection: "column"
                                }}>
                                    <Typography
                                        variant='h4'
                                    >
                                        {levelLabel(item.level)}
                                    </Typography>

                                    <Grid>
                                        <Typography>Descrição</Typography>
                                        <TextField
                                            value={item.description ?? ""}
                                            multiline
                                            rows={2}
                                            onChange={(e) => {
                                                setPolicies(policies.map((p) => {
                                                    if (p.id === item.id) {
                                                        return { ...p, description: e.target.value }
                                                    } else {
                                                        return p
                                                    }
                                                }))
                                            }}
                                        />
                                    </Grid>

                                    <Grid>
                                        <Typography>Taxa de comissão</Typography>
                                        <Grid width={"100%"}>
                                            <OutlinedInput
                                                value={`${String(item.percentage)}`}
                                                endAdornment={
                                                    <InputAdornment position='end'>
                                                        <Typography>%</Typography>
                                                    </InputAdornment>
                                                }
                                                placeholder='00.00%'
                                                onChange={(e) => {
                                                    if (e.target.value.slice(-1) === "." || !Number.isNaN(Number(e.target.value.slice(-1)))) {
                                                        setPolicies(policies.map((p) => {
                                                            if (p.id === item.id) {
                                                                return { ...p, percentage: Number(e.target.value) > 100 ? "100" : e.target.value as any }
                                                            } else {
                                                                return p
                                                            }
                                                        }))
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid>
                                        <Typography gutterBottom>Status</Typography>
                                        <Grid
                                            display={"flex"}
                                            flexDirection={"row"}
                                            justifyContent={"space-between"}
                                            alignItems={"center"}
                                        >
                                            <Typography variant='h5' color={item.active ? "green" : "red"}>{item.active ? "Ativo" : "Inativo"}</Typography>
                                            <Switch
                                                checked={item.active}
                                                onChange={(e) => {
                                                    setPolicies(policies.map((p) => {
                                                        if (p.id === item.id) {
                                                            return {
                                                                ...p,
                                                                active: e.target.checked
                                                            }
                                                        } else {
                                                            return p
                                                        }
                                                    }))
                                                }} />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>)
                    })}
                </Grid>
                <Divider sx={{
                    margin: '40px 0px'
                }} />
                <Grid my={4} pl={2.5}>
                    <Typography variant='h3'>Política desse usuário geral</Typography>
                </Grid>
                <Grid
                    container
                    display={"flex"}
                    flexDirection={"row"}
                    gap={2}
                    flexWrap={"wrap"}
                    justifyContent={"space-around"}>
                    <Card
                        sx={{ width: 300, padding: 2 }}>
                        <CardContent sx={{
                            display: "flex",
                            gap: 2, flexDirection: "column"
                        }}>
                            <Typography
                                variant='h4'
                            >
                                Este usuário vai receber
                            </Typography>

                            <Grid>
                                <Typography>Descrição</Typography>
                                <TextField
                                    value={policyOfUser?.description ?? ""}
                                    multiline
                                    rows={2}
                                    onChange={(e) => {
                                        //@ts-ignore
                                        setPolicyOfUser(prev => ({
                                            ...prev,
                                            description: e.target.value
                                        }))
                                    }}
                                />
                            </Grid>

                            <Grid>
                                <Typography>Taxa de comissão</Typography>
                                <Grid width={"100%"}>
                                    <OutlinedInput
                                        value={`${String(policyOfUser?.percentage ?? 0)}`}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <Typography>%</Typography>
                                            </InputAdornment>
                                        }
                                        placeholder='00.00%'
                                        onChange={(e) => {
                                            if (e.target.value.slice(-1) === "." || !Number.isNaN(Number(e.target.value.slice(-1)))) {
                                                //@ts-ignore
                                                setPolicyOfUser(prev => ({
                                                    ...prev,
                                                    percentage: Number(e.target.value) > 100 ? "100" : e.target.value as any
                                                }))
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid>
                                <Typography gutterBottom>Status</Typography>
                                <Grid
                                    display={"flex"}
                                    flexDirection={"row"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                >
                                    <Typography variant='h5' color={policyOfUser?.active ? "green" : "red"}>{policyOfUser?.active ? "Ativo" : "Inativo"}</Typography>
                                    <Switch
                                        checked={policyOfUser?.active}
                                        onChange={(e) => {
                                            //@ts-ignore
                                            setPolicyOfUser(prev => ({
                                                ...prev,
                                                active: e.target.checked
                                            }))
                                        }} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    color="inherit"
                    sx={{ backgroundColor: '#0000001A' }}
                    onClick={handleCloseChange} autoFocus>
                    Fechar
                </Button>
                <LoadingButton
                    loading={loading}
                    disabled={loading}
                    variant='contained'
                    color="primary"
                    sx={{ minWidth: 150 }}
                    onClick={saveChanges}
                >
                    Salvar alterações
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}