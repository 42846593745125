import { Button, Collapse } from "@mui/material"
import { useState } from "react"
import { IconType } from "react-icons"
import { useNavigate } from "react-router-dom"
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

export function MenuItemOption({ id, Icon, page, title, options, currentPage
}: {
    id: number,
    Icon: IconType,
    page: string,
    title: string,
    options?: {
        id: number,
        page: string,
        title: string
    }[],
    currentPage: number
}) {

    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    return (
        <>
            <Button
                onClick={() => {
                    if (options) {
                        setOpen(!open)
                    } else {
                        navigate(page)
                    }
                }}
                startIcon={<Icon />}
                endIcon={options ? open ? <IoIosArrowUp style={{
                    paddingLeft: '50px'
                }}/> : <IoIosArrowDown style={{
                    paddingLeft: '50px'
                }}/> : <></>}
                sx={{ justifyContent: 'flex-start'}}
                color={currentPage === id ? "primary" : "inherit"}
                variant={currentPage === id ? "contained" : "text"}
                fullWidth>
                {title}
            </Button>
            <Collapse in={open}>
                {options && options?.map((op) => {
                    return (
                        <Button
                            onClick={() => {
                                navigate(op.page)
                            }}
                            // startIcon={<Icon />}
                            sx={{ justifyContent: 'start', textIndent: '20px' }}
                            color={currentPage === id ? "primary" : "inherit"}
                            variant={currentPage === id ? "contained" : "text"}
                            fullWidth>
                            {op.title}
                        </Button>
                    )
                })}
            </Collapse>
        </>
    )
}