import * as React from 'react';
import { Grid, useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

interface TabeViewProps {
    header?: string[];
    align?: "center" | "justify" | "left" | "right" | "inherit"
    children: React.ReactNode;
    tableHeader?: React.ReactNode;
}

export default function TableView({ header, children, tableHeader, align }: TabeViewProps) {

    return (
        <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
            <Table sx={{ minWidth: 650, }} aria-label="simple table">
                {tableHeader ??
                    <TableHead sx={{ bgcolor: "#316FE8" }}>
                        <TableRow>
                            {
                                header?.map((_head) => (
                                    <TableCell align={align ?? undefined} sx={{ color: 'white' }}>{_head}</TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                }
                {children}
            </Table>
        </TableContainer>
    );
}