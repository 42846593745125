import { LoadingButton } from "@mui/lab";
import { Button, Card, CardContent, CardHeader, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PrizeDrawsService } from "service/concurso";
import { PrizeDrawsProps, ReportProps } from "types";

const ReportPage = () => {

    const [prizeFilter, setPrizeFilter] = useState<number | null>(null);

    const [report, setReport] = useState<ReportProps | null>(null);

    const [prizeDraws, setPrizeDraws] = useState<PrizeDrawsProps[] | null>(null);

    const [downlaodCorrespondentLoading, setDownlaodCorrespondentLoading] = useState<boolean>(false);
    const [downlaodClientLoading, setDownlaodClientLoading] = useState<boolean>(false);
    const [downloadPDFLoading, setDownloadPDFLoading] = useState<boolean>(false);
    const [reportLoading, setReportLoading] = useState(false)
    const [downlaodAllPrizeDrawLoading, setDownlaodAllPrizeDrawLoading] = useState<boolean>(false);
    const [loadingDownladNumbers, setLoadingDownladNumbers] = useState(false);

    const [linkDownload, setLinkDownload] = useState("")

    const GetReports = () => {
        if (prizeFilter) {
            setReportLoading(true)
            PrizeDrawsService.Report(prizeFilter)
                .then(({ data }) => {
                    if (data) { setReport(data) }
                })
                .catch(() => {
                    toast("Não foi possível buscar relatório", { type: 'error' })
                    setReport(null)
                }).finally(() => setReportLoading(false))
        }
    }

    useEffect(() => {
        GetReports();
    }, [prizeFilter])

    const getDownloadCorrespondent = async () => {
        setDownlaodCorrespondentLoading(true)
        try {
            const response = await PrizeDrawsService.downloadCorrespondent();
            if (response?.data) {
                window.open(response.data?.link, '_blank');
            }
        } catch (err) {
            toast('Não foi possivel baixar planilha', { type: 'error' })
        }
        finally { setDownlaodCorrespondentLoading(false) }
    }

    const getDownloadClient = async () => {
        setDownlaodClientLoading(true)
        try {
            const response = await PrizeDrawsService.downloadClient();
            if (response?.data) {
                window.open(response.data?.link, '_blank');
            }
        } catch (err) {
            toast('Não foi possivel baixar planilha', { type: 'error' })
        }
        finally { setDownlaodClientLoading(false) }
    }

    const getDownloadPrizeDrawResult = async () => {
        setDownloadPDFLoading(true)
        try {
            const response = await PrizeDrawsService.downloadPDF(prizeFilter as number);
            if (response?.data) {
                setLinkDownload(encodeURIComponent(response.data.link));
                window.open(response.data?.link, '_blank');
            }
        } catch (err: any) {
            toast(err?.response?.data?.message ?? 'Não foi possível baixar pdf', { type: 'error' })
        }
        finally { setDownloadPDFLoading(false) }
    }

    const listConcurso = () => {
        PrizeDrawsService.list({})
            .then(({ data }) => {
                if (data) {
                    setPrizeDraws(data?.prizeDraws)
                }
            })
            .catch(() => { })
    }

    const getDownloadllPrizeDraws = async () => {
        setDownlaodAllPrizeDrawLoading(true)
        try {
            const response = await PrizeDrawsService.downloadAllPrizeDraws();
            if (response?.data) {
                window.open(response.data?.link, '_blank');
            }
        } catch (err) {
            toast('Não foi possivel baixar planilha', { type: 'error' })
        }
        finally { setDownlaodAllPrizeDrawLoading(false) }
    }

    const fixNumber = (value: any) => {
        if (isNaN(value)) {
            return 0
        } else {
            return value
        }
    }

    const exportNumber = async () => {
        setLoadingDownladNumbers(true)
        try {
            const numbers = await PrizeDrawsService.exportnumber(prizeFilter as number)
            if (numbers?.data?.url) {
                window.open(numbers?.data?.url, '_blank');
            } else {
                toast("Não foi possível exportar palpites")
            }
        } catch (err) {
            toast("Não foi possível exportar palpites")
        }
        finally {
            setLoadingDownladNumbers(false)
        }
    }

    useEffect(() => {
        listConcurso();
    }, [])

    return (
        <Grid container p={4}>
            <Container>
                <Grid container alignItems="center" justifyContent={'space-between'}>
                    <Typography variant="h2">
                        Relatório sorteio ao vivo
                    </Typography>
                </Grid>
                <Grid container gap={2} alignItems={"center"}>
                    <FormControl sx={{ my: 3, maxWidth: 200, width: '100%' }}>
                        <InputLabel id="status-prize-select">Sorteio</InputLabel>
                        <Select
                            labelId="status-prize-select"
                            label="Sorteio"
                            sx={{ width: '100%' }}
                            value={prizeFilter}
                            onChange={(event) => setPrizeFilter(Number(event.target.value))}
                        >
                            {
                                prizeDraws?.sort((a, b) => b.id - a.id)?.map((prize) => (
                                    <MenuItem value={prize?.id}>
                                        {prize.id} {String(moment(prize.prizeDrawAt).format("dddd")).toUpperCase()} {String(moment(prize.prizeDrawAt).format("L")).toUpperCase()} às {moment(prize.prizeDrawAt).hours()}:{moment(prize.prizeDrawAt).minutes() > 9 ? moment(prize.prizeDrawAt).minutes() : "0" + moment(prize.prizeDrawAt).minutes()}h
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    {report && !reportLoading && <LoadingButton
                        loading={downloadPDFLoading}
                        onClick={getDownloadPrizeDrawResult}
                        variant="contained" sx={{ py: 2 }}>
                        Baixar resultado do concurso
                    </LoadingButton>}
                    <Grid>
                        <LoadingButton
                            loading={downlaodCorrespondentLoading}
                            onClick={getDownloadCorrespondent}
                            variant="contained"
                            sx={{ py: 2 }}>
                            Baixar planilha revendedores
                        </LoadingButton>
                    </Grid>
                    <LoadingButton
                        loading={downlaodClientLoading}
                        onClick={getDownloadClient}
                        variant="contained" sx={{ py: 2 }}>
                        Baixar planilha clientes
                    </LoadingButton>
                    <LoadingButton
                        loading={downlaodAllPrizeDrawLoading}
                        onClick={getDownloadllPrizeDraws}
                        variant="contained" sx={{ py: 2 }}>
                        Baixar planilha resultado de todos os sorteios
                    </LoadingButton>

                    {prizeFilter && <LoadingButton
                        loading={loadingDownladNumbers}
                        onClick={exportNumber}
                        variant="contained" sx={{ py: 2 }}>
                        Baixar apostas
                    </LoadingButton>}
                </Grid>
                <Grid container gap={2} mt={2}>
                    <Grid>
                        <Card sx={{ minHeight: 122 }}>
                            <CardContent>
                                <Typography variant="h4" fontWeight={"500"}>Total de Apostas</Typography>
                                <Grid container alignItems={"center"} justifyContent={"center"} mt={2}>
                                    <Typography variant="h2" fontWeight={"500"}>
                                        {report?.totalNumbers ?? 0}
                                    </Typography>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid>
                        <Card sx={{ minHeight: 122 }}>
                            <CardContent>
                                <Typography variant="h4" fontWeight={"500"}>Total de bilhetes</Typography>
                                <Grid container alignItems={"center"} justifyContent={"center"} mt={2}>
                                    <Typography variant="h2" fontWeight={"500"}>
                                        {report?.totalBets ?? 0}
                                    </Typography>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid>
                        <Card sx={{ minHeight: 122 }}>
                            <CardContent>
                                <Typography variant="h4" fontWeight={"500"}>Média de apostas</Typography>
                                <Grid container alignItems={"center"} justifyContent={"center"} mt={2}>
                                    <Typography variant="h2" fontWeight={"500"}>
                                        {(Number(report?.totalNumbers ?? 0) / Number(report?.totalBets ?? 0))?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid>
                        <Card sx={{ minHeight: 122 }}>
                            <CardContent>
                                <Typography variant="h4" fontWeight={"500"}>Total Vendido (R$)</Typography>
                                <Grid container alignItems={"center"} justifyContent={"center"} mt={2}>
                                    <Typography variant="h2" fontWeight={"500"}>
                                        {report?.totalSelled?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) ?? 0}
                                    </Typography>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid>
                        <Card sx={{ minHeight: 122 }}>
                            <CardContent>
                                <Typography variant="h4" fontWeight={"500"}>Total em Premiações Ganhas (R$)</Typography>
                                <Grid container alignItems={"center"} justifyContent={"center"} mt={2}>
                                    <Typography variant="h2" fontWeight={"500"}>
                                        {report?.totalPrizesPaid?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) ?? "-"}
                                    </Typography>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid>
                        <Card sx={{ minHeight: 122 }}>
                            <CardContent>
                                <Typography variant="h4" fontWeight={"500"}>Total em Comissões Geradas</Typography>
                                <Grid container alignItems={"center"} justifyContent={"center"} mt={2}>
                                    <Typography variant="h2" fontWeight={"500"}>
                                        {report?.totalCommissionGenerated?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) ?? 0}
                                    </Typography>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid>
                        <Card sx={{ minHeight: 122 }}>
                            <CardContent>
                                <Typography variant="h4" fontWeight={"500"}>Saldo bruto contábil</Typography>
                                <Grid container alignItems={"center"} justifyContent={"center"} mt={2}>
                                    <Typography variant="h2" fontWeight={"500"}>
                                        {report?.totalBalance?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) ?? 0}
                                    </Typography>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid>
                        <Card sx={{ minHeight: 122 }}>
                            <CardContent>
                                <Typography variant="h4" fontWeight={"500"}>Ganhadores</Typography>
                                <Grid container alignItems={"flex-start"} justifyContent={"flex-start"} mt={2} gap={3} flexWrap={'nowrap'}>
                                    <Grid>
                                        <Typography variant="h4" width="100%" fontWeight={"400"}>
                                            Total de ganhadores
                                        </Typography>
                                        <Typography variant="h4" mt={4} width="100%" fontWeight={"400"}>
                                            R$ (Valor total de cada premiação)
                                        </Typography>
                                    </Grid>
                                    {report?.winners?.map((item) => {
                                        return (
                                            <WinnersPapper key={item.order} text={item.legible} total={item.total} valueForOne={item.total * item.valueForOne} />

                                        )
                                    })}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Dialog open={linkDownload !== ""}>
                <DialogTitle>
                    <Typography variant="h4">
                        Deseja enviar link via Whatsapp?
                    </Typography>
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setLinkDownload("")}>Não</Button>
                    <Button variant="contained" onClick={() => {
                        window.open(`https://wa.me?text=${linkDownload}`, '_blank');
                    }}>Sim</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}
export default ReportPage;

const WinnersPapper = ({
    total,
    valueForOne,
    text
}: {
    total: number,
    valueForOne?: number,
    text: string
}) => (
    <Grid >
        <Typography variant="h2" fontWeight={"500"} textAlign="center">
            {total ?? 0}
        </Typography>
        <Typography variant="h2" textAlign="center" fontWeight={"500"} mt={2}>
            {valueForOne?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) ?? 0}
        </Typography>
        <Typography variant="h4" width="100%" fontWeight={"400"} textAlign="center" mt={2}>{text}</Typography>
    </Grid>
)