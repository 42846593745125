import { Box, Button, Container, Grid } from "@mui/material"
import SVGLOGO from "assets/imgs/logo.svg"
import { Outlet } from "react-router-dom"

const AuthLayout = () => {
    return (
        <Container maxWidth="xs">
            <Grid height='calc(100vh - 1rem)' p={2} justifyContent='center' container alignContent='flex-start'>
                <Box>
                    <Grid container flexDirection='column'>
                        <img src={SVGLOGO} width={100} />
                    </Grid>
                </Box>
                <Box width={'100%'}>
                    <Grid width={"100%"}>
                        <Outlet />
                    </Grid>
                </Box>
            </Grid>
        </Container>
    )
}
export default AuthLayout;