import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Autocomplete, DialogTitle, FormControl, FormHelperText, FormLabel, Grid, MenuItem, OutlinedInput, Select, TextField, Typography, useTheme } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { PrizeDrawsService } from 'service/concurso';
import { PrizeDrawsProps, UserGeralProps } from 'types';
import { toast } from 'react-toastify';
import { CNPJMaskCustom, CPFMaskCustom, CellphoneMaskCustom, PercentMaskInput, formatCPF } from 'components/mask';
import { Controller, useForm } from 'react-hook-form';
import { Validators } from 'utils/validators';
import { AuthService } from 'service/auth';
import { DatePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';

interface NewUserGeneralProps {
    open: boolean,
    handleClose: (user?: UserGeralProps) => void;
}

export default function NewUserGeneral({ open, handleClose }: NewUserGeneralProps) {

    const theme = useTheme();

    const [loading, setLoading] = useState(false);

    const [sellers, setSellers] = useState<UserGeralProps[]>([]);

    const [sellerError, setSellerError] = useState(false);

    const [sellerSelected, setSellerSelected] = useState<UserGeralProps | null>(null);

    const [type, setType] = useState<"pf" | "pj">("pf")

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        reset,
        control
    } = useForm<{ cpf: string, phone: string, password: string; birthday?: Moment | string | null }>()

    const onSubmit = async (data: any) => {
        setLoading(true)
        const birthday = getValues("birthday")
        AuthService.createUserGeral({
            cpf: getValues("cpf"),
            phone: getValues("phone"),
            password: getValues("password"),
            ...(sellerSelected && {
                userBase: sellerSelected.id
            }),
            ...(birthday && {
                birthday: moment(birthday).format("YYYY/MM/DD")
            })
        })
            .then(({ data }) => {
                toast("Usuário criado!", { type: 'success' });
                reset();
                setValue('cpf', '')
                setValue('password', '')
                setValue('phone', '')
                handleClose(data);
            })
            .catch((err) => {
                toast(err?.response?.data?.message?.error_description ?? err?.response?.data?.message ?? "Erro ao criar usuário!", { type: 'error' })
            })
            .finally(() => setLoading(false))

    }


    const handleCloseChange = () => {
        reset();
        handleClose()
    }

    const getSellers = async () => {
        AuthService.sellers()
            .then(({ data }) => {
                setSellers(data?.users ?? [])
            }).catch(() => setSellerError(true))
    }

    useEffect(() => {
        getSellers()
    }, [])

    return (
        <Dialog
            open={open}
            onClose={handleCloseChange}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { width: '100%' } }}
        >
            <DialogTitle>
                Criar novo usuário
            </DialogTitle>
            <DialogContent sx={{ minWidth: 300 }}>
                <Grid container gap={1}>
                    <Grid xs={12} md={12} mt={1}>
                        <FormControl fullWidth>
                            <FormLabel>Tipo de cadastro</FormLabel>
                            <Select id={"select-type-register"} fullWidth variant='outlined' value={type} onChange={(e) => {
                                setType(e.target.value as any)
                                setValue('cpf', '')
                                setValue('phone', '')
                                setSellerSelected(null)
                            }}>
                                <MenuItem value="pf">Pessoa Física</MenuItem>
                                <MenuItem value="pj">Pessoa Jurídica</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid xs={12} md={12} mt={1}>
                        <Autocomplete
                            options={sellers.filter((seller) => seller.cpf.length === (type === "pf" ? 11 : 14))}
                            id='select-base-seller'
                            blurOnSelect
                            fullWidth
                            renderInput={(params) => <TextField {...params} label="Selecionar vendedor base" />}
                            noOptionsText={sellers.length === 0 && !sellerError ? "A busca por vendedores ainda não foi concluída..." : sellerError ? "Houve uma falha ao buscar vendedores, tente fechar e abrir o modal" : "Nenhum vendedor foi encontrado com base no filtro"}
                            value={sellerSelected}
                            getOptionLabel={(option: UserGeralProps) => `${formatCPF(option.cpf)} - ${option.name}`}
                            onChange={(_, newValue: UserGeralProps | null) => {
                                setSellerSelected(newValue)
                                if (newValue) {
                                    setValue("cpf", newValue.cpf)
                                    setValue("phone", newValue.personalData.phone)
                                } else {
                                    setValue("cpf", "")
                                    setValue("phone", "")

                                }
                            }}
                        />
                    </Grid>

                    <Grid xs={12}>
                        <Typography>Informe o {type === "pf" ? "CPF" : "CNPJ"}</Typography>
                        <OutlinedInput
                            disabled={!!sellerSelected}
                            error={!!errors?.cpf}
                            placeholder={type === "pf" ? "000.000.000-00" : "00.000.000/0000-00"}
                            inputComponent={type === "pf" ? CPFMaskCustom : CNPJMaskCustom}
                            fullWidth
                            {...register("cpf", {
                                required: true,
                                validate: (value) => {
                                    if (!(type === "pf" ? Validators.validateCPF(value) : Validators.validateCNPJ(value))) {
                                        return `${type === "pf" ? "CPF" : "CNPJ"} inválido!`
                                    }
                                }
                            })}
                        />
                        <FormHelperText sx={{ color: 'red' }}>{errors?.cpf?.message}</FormHelperText>
                    </Grid>
                    <Grid xs={12} md={5.9}>
                        <Controller
                            name="birthday"
                            control={control}
                            defaultValue={null}
                            render={({
                                field: { value, ref, onBlur, onChange, disabled },
                            }) => (
                                <FormControl variant="outlined" fullWidth error={!!errors.birthday}>
                                    <FormLabel>Data de nascimento</FormLabel>
                                    <DatePicker
                                        slotProps={{
                                            textField: {
                                                error: false,
                                            },
                                        }}
                                        defaultValue={null}
                                        value={moment(value)}
                                        disableFuture
                                        inputRef={ref}
                                        format="DD/MM/YYYY"
                                        disabled={disabled}
                                        onChange={onChange}
                                        onAccept={onChange}
                                    />
                                    <FormHelperText>{errors.birthday?.message}</FormHelperText>
                                </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid xs={12} md={5.9}>
                        <Typography>Informe o Telefone</Typography>
                        <OutlinedInput
                            error={!!errors?.phone}
                            placeholder="(00) 0 0000-0000"
                            inputComponent={CellphoneMaskCustom}
                            fullWidth
                            {...register("phone", {
                                required: true,
                                validate: (value) => {
                                    if (!Validators.validateWhatsAppNumber(value)) {
                                        return "Telefone invalido!"
                                    }
                                }
                            })}
                        />
                        <FormHelperText sx={{ color: 'red' }}>{errors.phone?.message as string}</FormHelperText>
                    </Grid>
                    <Grid width={"100%"}>
                        <Typography>Senha</Typography>
                        <OutlinedInput
                            error={!!errors?.password}
                            placeholder="●●●●●●"
                            fullWidth
                            {...register("password", {
                                required: true,
                                minLength: {
                                    value: 4,
                                    message: "Senha deve possuir 4 ou mais dígitos"
                                },
                                maxLength: {
                                    value: 18,
                                    message: "Senha deve possuir 18 ou menos dígitos"
                                }
                            })}
                        />
                        <FormHelperText sx={{ color: 'red' }}>{errors.password?.message as string}</FormHelperText>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    color="inherit"
                    sx={{ backgroundColor: '#0000001A' }}
                    onClick={handleCloseChange} autoFocus>
                    Fechar
                </Button>
                <LoadingButton
                    loading={loading}
                    disabled={loading}
                    variant='contained'
                    color="primary"
                    sx={{ minWidth: 150 }}
                    onClick={handleSubmit(onSubmit)}>
                    Ciar usuário geral
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}