import { Button, Container, Grid, IconButton, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import TableView from "components/table";
import { useEffect, useState } from "react";
import { AuthService } from "service/auth";
import { IPoliciesProps, UserGeralProps } from "types";
import NewUserGeneral from "./new";
import { NameMask, formatCPF, formatPhone } from "components/mask";
import { FaMoneyBills } from "react-icons/fa6";
import { toast } from "react-toastify";
import HandlePolicies from "./policies";

const GeralPage = () => {

    const [userGeral, setUserGeral] = useState<null | UserGeralProps[]>(null);

    const [newUser, setNewUser] = useState(false);

    const [modalPolicies, setModalPolicies] = useState(false);

    const [policies, setPolicies] = useState<IPoliciesProps[]>([]);

    const [selectedUser, setSelectedUser] = useState<UserGeralProps>()

    const getUsers = () => AuthService.usersGeral()
        .then(({ data }) => {
            if (data?.users) {
                setUserGeral(data.users)
            }
        })
        .catch(() => { })

    const getPolicies = (id: number) => {
        AuthService.getPolicies(id)
            .then(({ data }) => {
                setPolicies(data.policies)
                setModalPolicies(true)
            }).catch((err) => toast(err?.response?.data?.message ?? "Erro ao buscar comissões", { type: 'error' }))
    }

    useEffect(() => {
        getUsers();
    }, [])

    return (
        <Grid container p={4}>
            <Container>
                <Grid container alignItems="center" justifyContent={'space-between'}>
                    <Typography variant="h2">
                        Lista de usuários geral
                    </Typography>
                    <Button variant="contained" onClick={() => setNewUser(true)}>
                        Novo usuário geral
                    </Button>
                </Grid>
                <Grid container gap={1} flexDirection={'column'} mt={2}>
                    <TableView
                        align='center'
                        header={['CPF', 'NOME', 'TELEFONE', 'COMISSÕES']}
                    >
                        {userGeral?.map((data) => (

                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        {formatCPF(data.cpf)}
                                    </TableCell>
                                    <TableCell align="center">
                                        {NameMask(data.name).firstName} {NameMask(data.name).lastName}
                                    </TableCell>
                                    <TableCell align="center">
                                        {formatPhone(data.personalData?.phone)}
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton onClick={() => {
                                            getPolicies(data.id)
                                            setSelectedUser(data)
                                        }}>
                                            <FaMoneyBills />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ))}

                    </TableView>
                </Grid>
            </Container>
            <NewUserGeneral
                open={newUser}
                handleClose={(user?: UserGeralProps) => {
                    if (user) {
                        getUsers()
                        setSelectedUser(user)
                        getPolicies(user.id)
                    }
                    setNewUser(false)
                }}
            />
            <HandlePolicies
                key={policies[0]?.generalId}
                open={modalPolicies}
                handleClose={() => {
                    setModalPolicies(false)
                    setPolicies([])
                }}
                policiesList={policies}
                user={selectedUser as UserGeralProps}
            />
        </Grid>
    )
}

export default GeralPage;