import { Utils } from "utils";

export default class StorageService {
    static getUser() {
        const user = localStorage.getItem(Utils.storageLocales.NFVtoken)
        if(user){
            return JSON.parse(user)
        }
        return null
    }

    static async saveToken(data: any){
        if(!data){
            return
        }
        localStorage.setItem(Utils.storageLocales.NFVtoken, JSON.stringify(data));
    }

    static async getToken(){
        const user = localStorage.getItem(Utils.storageLocales.NFVtoken)
        if(user){
            const user_json = JSON.parse(user)
            return user_json?.access
        }
        return null
    }
}