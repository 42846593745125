import { createBrowserRouter, useRoutes } from "react-router-dom"
import PublicRouters from "./public";
import { useState } from "react";
import useAuth from "context/auth";
import PrivateRouters from "./private";

const Routers = () => {
    const { isAuthenticated } = useAuth();
    return useRoutes(isAuthenticated ? PrivateRouters : PublicRouters);
}

export default Routers;