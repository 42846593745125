import { Box, Button, Collapse, Container, Grid, IconButton, MenuItem, SwipeableDrawer, Typography, useMediaQuery, useTheme } from "@mui/material"
import SVGLOGO from "assets/imgs/logo.svg"
import { IoMenu } from "react-icons/io5";
import { Outlet, useNavigate, useLocation, Link } from "react-router-dom"
import SwipeableTemporaryDrawer from "./menu";
import { useEffect, useState } from "react";
import useAuth from "context/auth";
import { FaFileAlt, FaHome, FaMoneyBill, FaUsers } from "react-icons/fa";
import { FaFileCircleCheck } from "react-icons/fa6";
import { TbCircleNumber0, TbReportAnalytics } from "react-icons/tb";
import { MenuItemOption } from "./item";
import { MdArrowForwardIos, MdClose, MdFileUpload, MdOutlineAttachMoney } from "react-icons/md";
import { ModalUploadVideo } from "components/modal/uploadVideo";

const AppLayout = () => {

    const { user } = useAuth();

    const { pathname } = useLocation();

    const theme = useTheme()

    const matches = useMediaQuery('(min-width:1100px)');

    const [currentPage, setCurrentPage] = useState(1);
    const [open, setOpen] = useState(false)
    const [openModalNewVideo, setOpenModalNewVideo] = useState(false)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuButton = Boolean(anchorEl);

    const ItemsNavigation = [
        // { id: 1, page: '/inicio', title: 'Inicio', Icon: FaHome },
        // { id: 2, page: '/resultados', title: 'Resultados', Icon: FaFileCircleCheck },
        { id: 7, page: '/caixa-ao-vivo', title: 'Caixa ao vivo', Icon: MdOutlineAttachMoney },
        { id: 3, page: '/solicitacoes', title: 'Solicitações', Icon: FaMoneyBill },
        { id: 4, page: '/relatorios-ao-vivo', title: 'Relatório sorteio ao vivo', Icon: FaFileAlt },
        { id: 4.1, page: '/relatorios-instantaneo', title: 'Relatório sorteio rápido', Icon: TbReportAnalytics },
        { id: 5, page: '/usuarios/geral', title: 'usuário geral', Icon: FaUsers },
        {
            id: 6, page: '/sorteio', title: 'Sorteio', Icon: TbCircleNumber0, options: [
                { id: 6.1, page: '/sorteio?horario=12:00', title: '12:00' },
                { id: 6.2, page: '/sorteio?horario=18:00', title: '18:00' },
                { id: 6.3, page: '/sorteio?horario=none', title: 'Sem horário' },
            ]
        },

    ]

    const handleOpenMenuButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenuButton = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        for (let index = 0; index < ItemsNavigation.length; index++) {
            const element = ItemsNavigation[index];
            if (pathname.includes(element.page)) {
                setCurrentPage(element.id)
            }
        }
    }, [pathname])

    return (
        <Grid
            height='100vh'
            flexDirection={'column'}
            width={'100vw)'}
            justifyContent='center'
            container
            alignContent='flex-start'>
            {matches && <Grid
                id="layout"
                width={"15.5rem"}
                container
                gap={5}
                alignContent='center'
                flexDirection={'column'}
                p={2}
                bgcolor="white"
                flexWrap={"wrap"}
                height={"100%"}>
                <img src={SVGLOGO} width={120} />
                <Grid container gap={1}>
                    {
                        ItemsNavigation.map(({ id, Icon, page, title, options
                        }) => <MenuItemOption Icon={Icon} currentPage={currentPage} id={id} page={page} title={title} key={id} options={options} />)
                    }
                    <Button
                        onClick={() => {
                           setOpenModalNewVideo(true)
                        }}
                        startIcon={<MdFileUpload/>}
                        sx={{ justifyContent: 'flex-start' }}
                        color={"inherit"}
                        variant={"text"}
                        fullWidth>
                        Adicionar novo vídeo
                    </Button>
                </Grid>
                <div>
                </div>
            </Grid>}
            {!matches &&
                <Grid
                    width={"40px"}
                    height={"100%"}
                    container
                    alignItems={"flex-start"}
                    sx={{
                        mr: "-40px"
                    }}
                >
                    <Grid container gap={1}>
                        <IconButton onClick={() => setOpen(true)}>
                            <IoMenu color={theme.palette.primary.main} style={{
                                width: 70,
                                height: 40,
                                marginTop: 17,
                                marginLeft: -15
                            }} />
                        </IconButton>
                    </Grid>
                </Grid>
            }

            {!matches && <SwipeableDrawer
                anchor={'left'}
                open={open}

                onClose={() => {
                    setOpen(false)
                    if (menuButton) handleCloseMenuButton()
                }}
                onOpen={() => { }}
                PaperProps={{
                    sx: { height: '100vh', width: '100%', maxWidth: 300 }
                }}
            >
                <Container maxWidth="xs" sx={{ p: 0 }}>
                    <Grid container bgcolor="white" height='100%' maxWidth={300} alignItems="center" justifyContent={"center"}>
                        <Grid p={4}>
                            <img src={SVGLOGO} width="100%" />
                        </Grid>
                        <Grid container mt={4}>
                            {
                                ItemsNavigation.filter((item) => !item.page.includes("/sorteio")).map(({ Icon, id, page, title, options }, index) => {
                                    const colorBG = page.includes(window.location.pathname) ? theme.palette.grey[300] : 'transparent';
                                    const color = page.includes(window.location.pathname) ? theme.palette.primary.main : "#666666";
                                    return (
                                        <>
                                            <Link to={page} style={{
                                                width: '100%',
                                                textDecoration: 'none'
                                            }}>
                                                <Grid
                                                    key={id}
                                                    sx={{ backgroundColor: colorBG, ':focus': { backgroundColor: colorBG } }}
                                                    justifyContent={'flex-start'}
                                                    borderRadius={0}
                                                    p={3}
                                                    container
                                                    flexWrap={'nowrap'}
                                                    gap={2}
                                                    alignItems="center"
                                                    component={Button}
                                                    onClick={() => {
                                                        setOpen(false)
                                                    }}
                                                >
                                                    <Icon style={{
                                                        color: color
                                                    }} size={20} />
                                                    <div>
                                                        <Typography textAlign="start" color={color} fontSize={16} fontWeight={500} maxWidth={200}>
                                                            {title}
                                                        </Typography>
                                                    </div>

                                                </Grid >
                                            </Link>
                                        </>
                                    )
                                })
                            }

                        </Grid>
                    </Grid>
                </Container>
            </SwipeableDrawer>
            }

            <Box
                width={`calc(100% - ${matches ? '15.5rem' : '0px'})`}
                sx={{ height: '100vh', overflowY: 'auto' }}>
                <Outlet />
            </Box>

            <ModalUploadVideo
                open={openModalNewVideo}
                handleClose={() => setOpenModalNewVideo(false)}
                key={'modal-new-voideo'}
            />
        </Grid>
    )
}
export default AppLayout;