import { IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { TiArrowUpThick } from "react-icons/ti";

const ButtonBackToTop = ({ containerRef }: { containerRef: React.RefObject<HTMLElement> }): JSX.Element => {
    const [visible, setVisible] = useState(false);

    const verificarScroll = () => {
        if (containerRef.current && containerRef.current.scrollTop > 300) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', verificarScroll);
        }
        return () => {
            if (container) {
                container.removeEventListener('scroll', verificarScroll);
            }
        };
    }, [containerRef]);

    const backToTop = () => {
        if (containerRef.current) {
            containerRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    if (visible) {
        return <IconButton
            onClick={backToTop}
            style={{
                position: 'fixed',
                bottom: '2rem',
                right: '2rem',
                fontSize: '1rem',
                backgroundColor: 'black',
                color: 'white',
                border: 'none',
                borderRadius: '50%',
                cursor: 'pointer',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                transition: 'opacity 0.3s ease',
                width: '40px',
                height: '40px',
            }}
        >
            <TiArrowUpThick/>
        </IconButton>
    } else {
        return <></>
    }

};

export default ButtonBackToTop;