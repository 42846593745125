import { ThemeProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routers from 'routes';
import theme from 'theme';
import { ptBR } from '@mui/x-date-pickers/locales';
import 'moment/dist/locale/pt-br'
import 'moment/locale/pt-br'
import moment from 'moment';
import { AuthProvider } from 'context/auth';
import { ToastContainer }  from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  moment.locale('pt-br')
  return (
    <BrowserRouter>
      <AuthProvider>
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale='pt-br'
          localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
          <ThemeProvider theme={theme()}>
            <Routers />
          </ThemeProvider>
        </LocalizationProvider>
      </AuthProvider>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
