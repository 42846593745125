import { useMediaQuery } from "@mui/material";
import React, {
    createContext,
    useContext,
    useEffect,
    useState
} from "react";
import { useLocation } from "react-router-dom";
import { AuthService } from "service/auth";
import StorageService from "service/storage";
import UserProps from "types/user";

interface ContextProps {
    Authenticate: (a: any) => void;
    isAuthenticated: boolean;
    user: UserProps | null

}

export const AuthManager = createContext({} as ContextProps);

const useAuth = () => useContext(AuthManager);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [token, seToken] = useState(() => {
        const current_user = StorageService.getUser()
        return current_user ?? null

    });

    const [user, setUser] = useState<UserProps | null>(null)
    const location = useLocation();

    const matches = useMediaQuery('(min-width:1100px)');


    const Authenticate = (data: any) => {
        seToken(data)
        StorageService.saveToken(data)
    }

    useEffect(() => {
        if (token) {
            AuthService.me()
                .then(({ data }) => {
                    if (data?.blocked) {
                        localStorage.clear()
                        window.location.reload()
                    }
                    setUser(data)
                    return
                })
        }
    }, [token])

    useEffect(() => {
      const layout = document.getElementById("layout")
      if (!location.pathname.includes("sorteio") && !layout && token && matches) {
        // window.location.reload()
      }
    }, [location])
    

    return (
        <AuthManager.Provider value={{
            isAuthenticated: !!token,
            Authenticate,
            user
        }}>
            {children}
        </AuthManager.Provider>
    )
}

export default useAuth;