import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Divider, FormControl, FormHelperText, Grid, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Typography, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ResultsService } from 'service/results';
import { toast } from 'react-toastify';
import { CreditsService } from 'service/credits';
import { WithdrawProps } from 'types/withdraw';


interface AlertWithDrawProps {
    open: boolean,
    handleClose: (reload?: boolean) => void;
    withdraw: WithdrawProps | null;
    type: 'cancel' | 'confirm' | null;
}

export default function AlertWithDraw({ open, handleClose, type, withdraw }: AlertWithDrawProps) {

    const [loading, setLoading] = useState(false);

    const confirmWithdraw = () => {
        if (!withdraw) {
            return
        }
        setLoading(true)
        CreditsService.confirm(withdraw?.id)
            .then(() => {
                toast('Solicitação maracada como paga', { type: 'success' })
                handleClose(true)
            })
            .catch((err) => toast(err?.response?.data?.message ?? 'Erro ao marcar solicitação como paga', { type: 'error' }))
            .finally(() => setLoading(false))
    }

    const cancelWithdraw = () => {
        if (!withdraw) {
            return
        }
        setLoading(true)
        CreditsService.cancel(withdraw?.id)
            .then(() => {
                toast('Solicitação cancelada', { type: 'success' })
                handleClose(true)
            })
            .catch((err) => toast(err?.response?.data?.message ?? 'Erro ao cancelar solicitação', { type: 'error' }))
            .finally(() => setLoading(false))
    }
    

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent sx={{ minWidth: 300 }}>
                Você realmente deseja {type === 'cancel' ? 'cancelar solicitação' : type === 'confirm' ? 'Marcar como paga a solicitação' : ''} de {withdraw?.user?.name} no valor de
                {' '} R$ {withdraw?.creditValue.toLocaleString('pt-BR', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    color="inherit"
                    sx={{ backgroundColor: '#0000001A' }}
                    onClick={() => handleClose(false)} autoFocus>
                    Fechar
                </Button>
                <LoadingButton
                    variant='contained'
                    disabled={loading}
                    loading={loading}
                    color="primary"
                    sx={{ minWidth: 150 }}
                    onClick={() => {
                        if (type === 'cancel') {
                            cancelWithdraw()
                        }
                        if (type === 'confirm') {
                            confirmWithdraw()
                        }
                    }}>
                    {type === 'cancel' ? 'Cancelar solicitação' : 'Confirmar solicitação como paga'}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
