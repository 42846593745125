import App from "pages/app";
import AuthLayout from "layout/auth";
import AppLayout from "layout/app";
import { Navigate } from "react-router-dom";
import ResultsPage from "pages/app/results";
import WithdrawPage from "pages/app/withdraw";
import ReportPage from "pages/app/report";
import GeralPage from "pages/app/geral";
import { PrizeDraw } from "pages/app/prizeDraw";
import { CashClosed } from "pages/app/cashClosed";
import { ReportExpress } from "pages/app/report/express";

const PrivateRouters = [
    {
        ...(!window.location.pathname.includes("sorteio") && {
            element: <AppLayout />,

        }),
        path: "",
        children: [
            {
                element: <Navigate to="/solicitacoes" />,
                path: "/",
            },
            {
                element: <Navigate to="/solicitacoes" />,
                path: "/*",
            },
            // {
            //     element: <App />,
            //     path: "/inicio",
            // },
            // {
            //     element: <ResultsPage />,
            //     path: "/resultados",
            // },
            {
                element: <CashClosed />,
                path: "/caixa-ao-vivo",
            },
            {
                element: <WithdrawPage />,
                path: "/solicitacoes",
            },
            {
                element: <ReportPage />,
                path: "/relatorios-ao-vivo",
            },
            {
                element: <ReportExpress />,
                path: "/relatorios-instantaneo",
            },
            {
                element: <GeralPage />,
                path: "/usuarios/geral",
            },
            {
                element: <PrizeDraw/>,
                path: "/sorteio"
            }
        ]
    },
]

export default PrivateRouters;