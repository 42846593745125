export function transformValue(value: string | number) {
    const newValue = String(value).replace('.', '').replace(',', '').replace(/\D/g, '')
    if (split(newValue, newValue.length - 2)[1] === '00') {
        return Number(split(newValue, newValue.length - 2)[0])
    } else {
        const array = split(newValue, newValue.length - 2)
        return Number(array[0] + '.' + array[1])
    }
}

export function split(str: string, index: number) {
    const result = [str.slice(0, index), str.slice(index)];
    return result;
}

export function round(value: number, precision = 2) {
    const power = 10 ** precision;
    return Math.round(parseFloat((value * power).toPrecision(15))) / power;
}