import React, { useEffect } from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

export const moneyMask = (value: string) => {
  if (!value) {
    return 'R$ 0,00'
  }
  value = value?.replace('.', '')?.replace(',', '')?.replace(/\D/g, '')

  const options = { minimumFractionDigits: 2 }
  const result = new Intl.NumberFormat('pt-BR', options).format(
    parseFloat(value) / 100
  )

  return 'R$ ' + result
}

const BRLMaskedInput = (props: any) => {
  const defaultMaskOptions = {
    prefix: 'R$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 7,
    allowNegative: false,
    allowLeadingZeroes: false,
  }
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
  })
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        if (inputRef && ref) {
          inputRef(ref.inputElement);
        }
      }}
      mask={currencyMask}
      guide={false}
      placeholderChar={'\u2000'}
    />
  );
};

function CNPJMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef && inputRef(ref ? ref?.inputElement : null);
      }}
      mask={[
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );

}

const maskCPF = (cpf: string) => {
  return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.xxx.xxx-$4");
};

const formatCPF = (cpf: string) => {
  if (cpf) {
        cpf = cpf?.replace(/\D/g, '');

        if (cpf.length === 11) {
            cpf = cpf?.replace(/(\d{3})(\d)/, '$1.$2');
            cpf = cpf?.replace(/(\d{3})(\d)/, '$1.$2');
            cpf = cpf?.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
        } else {
            cpf = cpf?.replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5");
        }
    }

    return cpf;
};

const formatPhone = (phone: string) => {
  return phone?.replace(/^(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3');
};

function CPFMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
}

function KeyPixRandomMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        '-',
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        '-',
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        '-',
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        '-',
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
      ]}
      guide={false}
    />
  );
}

function CellphoneMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
}

function PercentMaskInput(props: any) {
  const { inputRef, ...other } = props;
  const percentMask = (value: any) => {
    const numberRegex = /^\d+\.?\d*$/;
    const decimalRegex = /^(\d+)\.(\d+)$/;
    let mask = [];

    if (decimalRegex.test(value)) {
      mask = [
        ...value?.split('').map((char: any) => (numberRegex.test(char) ? /\d/ : char)),
        '%'
      ];
    } else if (value?.endsWith('%')) {
      mask = [...value?.split('').map((char: any) => (numberRegex.test(char) ? /\d/ : char))];
    } else {
      mask = [/\d/, '%'];
    }

    return mask;
  };
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={percentMask}
      guide={false}
    />
  );
}


const CEPMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

function CEPMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={CEPMask}
      placeholderChar={'\u2000'}
      guide={false}
      showMask
    />
  );
}

function AgencyNumberInputMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      guide={false}
      showMask
    />
  );
}

function AgencyDigitInputMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[A-Za-z0-9]/, /[A-Za-z0-9]/]}
      placeholderChar={'\u2000'}
      guide={false}
      showMask
    />
  );
}

function AccountNumberInputMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      guide={false}
      showMask
    />
  );
}

function AccountDigitInputMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[A-Za-z0-9]/, /[A-Za-z0-9]/]}
      placeholderChar={'\u2000'}
      guide={false}
      showMask
    />
  );
}

function POSMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        'N',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
}

const CNAEMask = [/\d/, /\d/, '.', /\d/, /\d/, '-', /\d/, '-', /\d/, /\d/];

function CNAEInputMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={CNAEMask}
      guide={false}
    />
  );
}


const NameMask = (value: string) => {
  if (!value) {
    return {
      firstName: "",
      lastName: ""
    }
  }
  var nameSplit = value?.split(" ");
  var firstName = nameSplit[0];
  var lastName = nameSplit[nameSplit.length - 1];
  return {
    firstName,
    lastName
  };
}

export {
  CNPJMaskCustom,
  CPFMaskCustom,
  CellphoneMaskCustom,
  CEPMaskCustom,
  KeyPixRandomMaskCustom,
  AgencyNumberInputMaskCustom,
  AgencyDigitInputMaskCustom,
  AccountNumberInputMaskCustom,
  AccountDigitInputMaskCustom,
  POSMaskCustom,
  CNAEInputMaskCustom,
  PercentMaskInput,
  CNAEMask,
  CEPMask,
  BRLMaskedInput,
  maskCPF,
  NameMask,
  formatCPF,
  formatPhone
};
