import { LoadingButton } from "@mui/lab";
import { Autocomplete, Card, CardContent, CardHeader, Collapse, Container, Divider, FormControl, FormLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TableBody, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { formatCPF } from "components/mask";
import TableView from "components/table";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { AuthService } from "service/auth";
import { PrizeDrawsService } from "service/concurso";
import { ReportsService } from "service/reports";
import { PrizeDrawsProps, UserGeralProps } from "types";

export interface IReport {
    in: In
    out: Out
    balance: Balance
    finalCredits: FinalCredits
    bets: Bets
    receipt: Receipt
    prize: Prize
    commission: number
    accountingSummary: number
    withdraw: Withdraw
    financialSummary: number
    accountingReconciliation: number
}

interface In {
    pix: Pix
    credits: Credits
    total: number
}

interface Pix {
    mercadoPago: number
    openPix: number
    primepago: number
    total: number
    pagarme: number,
    mercadoPagoLiquid: number
    primePagLiquid: number
}

interface Credits {
    normal: number
    guess: number
    total: number
}

interface Out {
    creditsGenerated: CreditsGenerated
    bonus: number
    transferCompleted: number
}

interface CreditsGenerated {
    prizes: number
    commissions: number
    total: number
}

interface Balance {
    pix: number
    credits: number
}

interface FinalCredits {
    users: number
    clients: number
    total: number
}

interface Bets {
    voucher: Voucher
    online: Online
    total: Total
    player: Player
}

interface Voucher {
    quantity: number
    value: number
    numbers: number
}

interface Online {
    quantity: number
    value: number
    numbers: number
}

interface Total {
    qtd: number
    value: number
    numbers: number
}

interface Receipt {
    pix: number
    credits: number
    guess: number
    total: number
    totalLiquid: number
    pixLiquid: number
}

interface Prize {
    total: number
    pix: number
    guess: number
    list: {
        quadra: {
            quantity: number,
            value: number
        }
        terno: {
            quantity: number,
            value: number
        }
        duque: {
            quantity: number,
            value: number
        }
        guess: {
            quantity: number,
            value: number
        }
        inverter: {
            quantity: number,
            value: number
        }
        vizUp: {
            quantity: number,
            value: number
        }
        vizDown: {
            quantity: number,
            value: number
        }
        favorite: {
            quantity: number,
            value: number
        }
    }
}

interface Withdraw {
    client: number
    seller: number
    total: number
}

interface Player {
    total: number
    online: number
    voucher: number
}

export const CashClosed = () => {
    const [report, setReport] = useState<IReport>()
    const [startAt, setStartAt] = useState(moment())
    const [endAt, setEndAt] = useState(moment())
    const [prizeDraw, setPrizeDraw] = useState<string | null>(null)
    const [generalId, setGeneralId] = useState<string | null>(null)
    const [userId, setUserId] = useState<UserGeralProps | null>(null)

    const [prizeDraws, setPrizeDraws] = useState<PrizeDrawsProps[] | null>(null);
    const [generals, setGenerals] = useState<UserGeralProps[] | null>(null);
    const [sellers, setSellers] = useState<UserGeralProps[]>([]);

    const [loading, setLoading] = useState(false)
    const [loadingExport, setLoadingExport] = useState(false)

    const getReport = (download: boolean = false) => {
        if (download) {
            setLoadingExport(true)
        } else {
            setLoading(true)
        }
        ReportsService.get({
            "end-at": moment(endAt).format("YYYY-MM-DD"),
            "start-at": moment(startAt).format("YYYY-MM-DD"),
            ...(prizeDraw && {
                "prize-draw": prizeDraw
            }),
            ...(generalId && {
                "general-id": generalId
            }),
            ...(userId && {
                "user-id": userId.id.toString()
            }),
            ...(download && {
                export: true
            })
        }).then(({ data }) => {
            console.log(data)
            if (download) {
                window.open(data.url, '_blank');
            } else {
                setReport(data)
            }
        }).finally(() => {
            if (download) {
                setLoadingExport(false)
            } else {
                setLoading(false)
            }
        })
    }

    const listPrizeDraws = () => {
        PrizeDrawsService.list({
            status: 50
        })
            .then(({ data }) => {
                if (data) {
                    setPrizeDraws(data?.prizeDraws)
                }
            })
            .catch(() => { })
    }

    const getUsers = () => AuthService.usersGeral()
        .then(({ data }) => {
            if (data?.users) {
                setGenerals(data.users)
            }
        })
        .catch(() => { })

    const getSellers = async () => {
        AuthService.sellers()
            .then(({ data }) => {
                setSellers(data?.users ?? [])
            })
    }

    useEffect(() => {
        getUsers()
        listPrizeDraws()
        getSellers()
        getReport()
    }, [])

    const space = () => {
        return (
            <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="left" style={{ fontWeight: 'bold' }}><br /></TableCell>
                <TableCell align="left"></TableCell>
            </TableRow>
        )
    }


    return (
        <Grid container p={4}>
            <Container>
                <Grid container alignItems="center" justifyContent={'space-between'}>
                    <Typography variant="h2">
                        Caixa ao vivo
                    </Typography>
                </Grid>

                <Grid container mt={4} gap={2}>
                    <FormControl>
                        <DesktopDatePicker
                            format="DD/MM/YYYY"
                            label="Data de início"
                            value={startAt}
                            onChange={(event) => {
                                setStartAt(event as Moment)
                            }}
                        />
                    </FormControl>

                    <FormControl>
                        <DesktopDatePicker
                            format="DD/MM/YYYY"
                            label="Data de fim"
                            value={endAt}
                            onChange={(event) => {
                                setEndAt(event as Moment)
                            }}
                        />
                    </FormControl>

                    <FormControl sx={{
                        minWidth: '200px'
                    }}>
                        <InputLabel id="prizeDraw">Concurso</InputLabel>
                        <Select
                            labelId="prizeDraw"
                            label="Concurso"
                            sx={{ width: '100%' }}
                            value={prizeDraw}
                            onChange={(event) => setPrizeDraw(event.target.value)}
                            endAdornment={
                                prizeDraw ? <InputAdornment position="end">
                                    <IconButton onClick={() => setPrizeDraw(null)} sx={{
                                        mr: 2
                                    }}>
                                        <MdClose size={20} />
                                    </IconButton>
                                </InputAdornment> : ""
                            }
                        >
                            {
                                prizeDraws?.sort((a, b) => b.id - a.id)?.map((prize) => (
                                    <MenuItem value={prize?.id}>
                                        {prize.id} {String(moment(prize.prizeDrawAt).format("dddd")).toUpperCase()} {String(moment(prize.prizeDrawAt).format("L")).toUpperCase()} às {moment(prize.prizeDrawAt).hours()}:{moment(prize.prizeDrawAt).minutes() > 9 ? moment(prize.prizeDrawAt).minutes() : "0" + moment(prize.prizeDrawAt).minutes()}h
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    <FormControl sx={{
                        minWidth: '200px'
                    }}>
                        <InputLabel id="general">Usuário geral</InputLabel>
                        <Select
                            labelId="general"
                            label="Usuário geral"
                            sx={{ width: '100%' }}
                            value={generalId}
                            onChange={(event) => setGeneralId(event.target.value)}
                            endAdornment={
                                generalId ? <InputAdornment position="end">
                                    <IconButton onClick={() => setGeneralId(null)} sx={{
                                        mr: 2
                                    }}>
                                        <MdClose size={20} />
                                    </IconButton>
                                </InputAdornment> : ""
                            }
                        >
                            {
                                generals?.sort((a, b) => b.id - a.id)?.map((general) => (
                                    <MenuItem value={general.id}>
                                        {general.id} - {general.name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    <FormControl sx={{
                        minWidth: '300px'
                    }}>
                        <Autocomplete
                            options={sellers}
                            id='seller'
                            blurOnSelect
                            fullWidth
                            renderInput={(params) => <TextField {...params} label="Selecionar Revendedor" />}
                            noOptionsText={sellers.length === 0 ? "A busca por Revendedores ainda não foi concluída..." : ""}
                            value={userId}
                            getOptionLabel={(option: UserGeralProps) => `${formatCPF(option.cpf)} - ${option.name}`}
                            onChange={(_, newValue: UserGeralProps | null) => {
                                setUserId(newValue)
                            }}
                        />
                    </FormControl>

                    <LoadingButton sx={{
                        minWidth: '150px'
                    }} variant="contained" loading={loading} disabled={loading} onClick={() => getReport()}>Buscar</LoadingButton>

                    <LoadingButton sx={{
                        minWidth: '150px'
                    }} variant="outlined" loading={loadingExport} disabled={loadingExport} onClick={() => getReport(true)}>Baixar planilha</LoadingButton>


                </Grid>

                <Collapse in={!loading}>
                    <Grid container mt={4}>
                        <TableView
                            align='left'
                            header={['ITEM', 'DESCRIÇÃO', 'VALOR']}
                        >
                            <TableBody>

                                <TableRow>
                                    <TableCell align="left">1</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>APOSTAS</TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">1.1</TableCell>
                                    <TableCell align="left">Quantidade Total de Apostas</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.bets.total.qtd}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">1.1.1</TableCell>
                                    <TableCell align="left">Quantidade de Apostas Online</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.bets.online.quantity}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">1.1.2</TableCell>
                                    <TableCell align="left">Quantidade de Apostas via Voucher</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.bets.voucher.quantity}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">1.2</TableCell>
                                    <TableCell align="left">Quantidade Total de Apostadores</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.bets.player.total}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">1.2.1</TableCell>
                                    <TableCell align="left">Quantidade de Apostadores Online</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.bets.player.online}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">1.2.2</TableCell>
                                    <TableCell align="left">Quantidade de Apostadores via Voucher</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.bets.player.voucher}</TableCell>
                                </TableRow>


                                <TableRow>
                                    <TableCell align="left">1.3</TableCell>
                                    <TableCell align="left">Quantidade Total de Palpites</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.bets.total.numbers}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">1.3.1</TableCell>
                                    <TableCell align="left">Quantidade de Palpites Online</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.bets.online.numbers}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">1.3.2</TableCell>
                                    <TableCell align="left">Quantidade de Palpites via Voucher</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.bets.voucher.numbers}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">1.4</TableCell>
                                    <TableCell align="left">Valor Total das Apostas</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.bets.total.value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">1.4.1</TableCell>
                                    <TableCell align="left">Valor das Apostas Online</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.bets.online.value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>


                                <TableRow>
                                    <TableCell align="left">1.4.2</TableCell>
                                    <TableCell align="left">Valor das Apostas via Voucher</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.bets.voucher.value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                {space()}

                                <TableRow>
                                    <TableCell align="left">2</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>RECEBIMENTOS DAS APOSTAS</TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">2.1</TableCell>
                                    <TableCell align="left">Recebimento Total Bruto das Apostas</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.receipt.total?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">2.1.1</TableCell>
                                    <TableCell align="left">Recebimento Total Líquido das Apostas (-taxas)</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.receipt.totalLiquid?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">2.1.2</TableCell>
                                    <TableCell align="left">Recebimento Bruto via PIX</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.receipt.pix?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">2.1.2.1</TableCell>
                                    <TableCell align="left">Recebimento Líquido via PIX (-taxas)</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.receipt.pixLiquid?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">2.1.2.1.1</TableCell>
                                    <TableCell align="left">Recebimento Bruto via PIX  primepag</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.in.pix.primepago?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">2.1.2.1.2</TableCell>
                                    <TableCell align="left">Recebimento Líquido via PIX (-taxas) primepag</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.in.pix.primePagLiquid?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">2.1.2.2.1</TableCell>
                                    <TableCell align="left">Recebimento Bruto via PIX  mercadopago</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.in.pix.mercadoPago?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">2.1.2.2.2</TableCell>
                                    <TableCell align="left">Recebimento Líquido via PIX (-taxas)  mercadopago</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.in.pix.mercadoPagoLiquid?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">2.1.3</TableCell>
                                    <TableCell align="left">Recebimento via valor para saque</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.receipt.credits?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">2.1.4</TableCell>
                                    <TableCell align="left">Recebimento via créditos para apostas</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.receipt.guess?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                {space()}

                                <TableRow>
                                    <TableCell align="left">3</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>PRÊMIOS GERADOS</TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">3.1</TableCell>
                                    <TableCell align="left">Prêmios Totais Gerados</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold', }}>{report?.prize.total?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">3.1.1</TableCell>
                                    <TableCell align="left">Prêmios Gerados para Pagmento via PIX</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold', }}>{report?.prize.pix?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">3.1.1.1</TableCell>
                                    <TableCell align="left">Quantidade de Prêmios de QUADRA</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.prize.list.quadra.quantity}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">3.1.1.1.1</TableCell>
                                    <TableCell align="left">Valor de Prêmios de QUADRA</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold', }}>{report?.prize.list.quadra.value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">3.1.1.2</TableCell>
                                    <TableCell align="left">Quantidade de Prêmios de TERNO</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.prize.list.terno.quantity}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">3.1.1.2.1</TableCell>
                                    <TableCell align="left">Valor de Prêmios de TERNO</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold', }}>{report?.prize.list.terno.value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">3.1.1.3</TableCell>
                                    <TableCell align="left">Quantidade de Prêmios de DUQUE</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.prize.list.duque.quantity}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">3.1.1.3.1</TableCell>
                                    <TableCell align="left">Valor de Prêmios de DUQUE</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold', }}>{report?.prize.list.duque.value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                {/* <TableRow>
                                    <TableCell align="left">3.1.2</TableCell>
                                    <TableCell align="left">Prêmios Gerados para Pagmento via BÔNUS</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold', }}>{report?.prize.guess?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow> */}

                                <TableRow>
                                    <TableCell align="left">3.1.1.4</TableCell>
                                    <TableCell align="left">Quantidade de Prêmios de UNIDADE</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.prize.list.guess.quantity}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">3.1.1.4.1</TableCell>
                                    <TableCell align="left">Valor de Prêmios de UNIDADE</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold', }}>{report?.prize.list.guess.value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">3.1.1.5</TableCell>
                                    <TableCell align="left">Quantidade de Prêmios de INVERTIDO</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.prize.list.inverter.quantity}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">3.1.1.5.1</TableCell>
                                    <TableCell align="left">Valor de Prêmios de INVERTIDO</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold', }}>{report?.prize.list.inverter.value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">3.1.1.6</TableCell>
                                    <TableCell align="left">Quantidade de Prêmios de NÚMERO FAVORITO</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.prize.list.favorite.quantity}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">3.1.1.6.1</TableCell>
                                    <TableCell align="left">Valor de Prêmios de NÚMERO FAVORITO</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold', }}>{report?.prize.list.favorite.value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">3.1.1.7</TableCell>
                                    <TableCell align="left">Quantidade de Prêmios de VIZINHO DE CIMA</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.prize.list.vizUp.quantity}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">3.1.1.7.1</TableCell>
                                    <TableCell align="left">Valor de Prêmios de VIZINHO DE CIMA</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold', }}>{report?.prize.list.vizUp.value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">3.1.1.8</TableCell>
                                    <TableCell align="left">Quantidade de Prêmios de VIZINHO DE BAIXO</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.prize.list.vizDown.quantity}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">3.1.1.8.1</TableCell>
                                    <TableCell align="left">Valor de Prêmios de VIZINHO DE BAIXO</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold', }}>{report?.prize.list.vizDown.value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                {space()}

                                <TableRow>
                                    <TableCell align="left">4</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>COMISSÕES GERADAS</TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">4.1</TableCell>
                                    <TableCell align="left">Comissão Total Gerada</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold', }}>{report?.commission?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                {space()}

                                <TableRow>
                                    <TableCell align="left">5</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>SAQUES VIA PIX</TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">5.1</TableCell>
                                    <TableCell align="left">Saque Total via PIX - OPENPIX</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold', }}>{report?.withdraw?.total?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">5.1.1</TableCell>
                                    <TableCell align="left">Saque via PIX - CLIENTE</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold', }}>{report?.withdraw?.client?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left">5.1.2</TableCell>
                                    <TableCell align="left">Saque via PIX - REVENDEDOR</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold', }}>{report?.withdraw?.seller?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                {space()}


                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>RESUMO CONTÁBIL</TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left">Valor Total das Apostas</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.receipt.total?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left">Taxas de Adquirente</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold', color: 'red' }}>{((report?.receipt?.pix as number) - (report?.receipt.pixLiquid as number))?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left">Prêmios Totais Gerados</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold', color: 'red' }}>{report?.prize.total?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left">Comissão Total Gerada</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold', color: 'red' }}>{report?.commission?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left">Resultado Contábil</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold', color: (report?.accountingSummary ?? 0) >= 0 ? "inherit" : "red" }}>{report?.accountingSummary?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                {space()}

                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>RESUMO FINANCEIRO</TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left">Recebimento Líquido via PIX (-taxas)</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>{report?.receipt.pixLiquid?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left">Prêmios Gerados para Pagmento via PIX</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold', color: 'red' }}>{report?.prize.pix?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left">Comissão Total Gerada</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold', color: 'red' }}>{report?.commission?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left">Resultado Financeiro</TableCell>
                                    <TableCell align="left" style={{ fontWeight: 'bold', color: (report?.financialSummary ?? 0) >= 0 ? "inherit" : "red" }}>{report?.financialSummary?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>

                            </TableBody>
                        </TableView>
                    </Grid>
                </Collapse>

            </Container>
        </Grid>
    )
}