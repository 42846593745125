import api from "../api"

export class ReportsService {
    static get(data: {
        "start-at": string,
        "end-at": string,
        "prize-draw"?: string,
        "general-id"?: string,
        "user-id"?: string,
        "export"?: boolean
    }) {
        return api.get(`/reports/cash-closing`, {
            params: data
        })
    }

    static getInstReport(data: {
        begin: string,
        end: string,
        clients?: number[],
    }) {
        return api.post(`/inst/bets/reports/abstract`, data)
    }
}