import { Button, ButtonGroup, CircularProgress, Container, Grid, IconButton, TableBody, TableCell, TableRow, Tooltip, Typography } from "@mui/material"
import { NameMask, formatCPF, formatPhone, maskCPF } from "components/mask";
import TableView from "components/table";
import { useEffect, useState } from "react";
import { FaCheck, FaCopy, FaRegCopy } from "react-icons/fa";
import { FaX } from "react-icons/fa6";
import { FiCheck, FiX } from "react-icons/fi";
import { CreditsService } from "service/credits";
import { WithdrawProps, keyPixTypeProp } from "types/withdraw";

import copy from 'copy-to-clipboard';
import { toast } from "react-toastify";
import AlertWithDraw from "./alert";
import moment from "moment";
import { PaginationComponent } from "components/pagination";


const WithdrawPage = () => {

    const [widthdraws, setWithdraws] = useState<WithdrawProps[] | null>(null);

    const [confirmWithdraw, setCofirmWithdraw] = useState<WithdrawProps | null>(null);
    const [cancelWithdraw, setCancelWithdraw] = useState<WithdrawProps | null>(null);

    const [loadingCancel, setLoadingCancel] = useState<number | null>(null);

    const [total, setTotal] = useState<number>(0)

    const ListWithdraw = (params?: {
        page: number,
        limit: number
    }) => {
        CreditsService.list(params)
            .then(({ data }) => {
                setWithdraws(data?.credits ?? [])
                setTotal(data?.total)
            })
            .catch(() => { })
    }

    useEffect(() => {
        ListWithdraw();
    }, [])


    const ViewKindPix = (keyPixType: keyPixTypeProp) => {
        if (keyPixType === 'cpf') {
            return "CPF"
        }
        if (keyPixType === 'phone') {
            return "TELEFONE"
        }
        if (keyPixType === 'cnpj') {
            return "CNPJ"
        }
        if (keyPixType === 'uuid') {
            return "CHAVE ALEATÓRIA"
        }
        if (keyPixType === 'email') {
            return "E-MAIL"
        }
    }

    const renderStatus = (status: number): {
        color: string,
        label: string
    } => {
        switch (status) {
            case 0:
                return {
                    label: "Pendente",
                    color: 'orange'
                }
            case 3:
                return {
                    label: "Pago",
                    color: 'green'
                }
            case 2:
                return {
                    label: "Estornada",
                    color: 'red'
                }
            default:
                return {
                    label: "Status desconhecido",
                    color: 'inherit'
                }
        }
    }

    const renderIntegration = (integration: number): string  => {
        switch (integration) {
            case 1:
                return "MERCADO PAGO"
            case 2:
                return "PAGARME"
            case 3:
                return "OPENPIX"
            case 4:
                return "PRIMEPAG"
            default:
                return ""
        }
    }

    return (
        <Container sx={{
            maxWidth: '1550px !important'
        }}>
            <Grid container p={4}>
                <Grid container alignItems="center" justifyContent={'space-between'}>
                    <Typography variant="h2">
                        Solicitações de saque
                    </Typography>
                </Grid>
                <Grid container mt={4}>
                    <TableView
                        align='center'
                        header={['Data da solicitação', 'Nome', 'CPF', 'WhatsApp', 'Tipo de usuário', 'Tipo de chave PIX', 'Chave PIX', 'Valor', 'Cep', 'Cidade', 'Status', 'ID', 'Integração']}
                    >
                        <TableBody>
                            {
                                widthdraws?.map((_withdraw) => {

                                    const { user, status, id, creditValue, client, payment } = _withdraw

                                    const nameUser = NameMask(user?.name ?? '')
                                    const clientUser = NameMask(client?.name ?? '')

                                    const _status = renderStatus(status)

                                    return (

                                        <TableRow key={id}>
                                            <TableCell align="center">
                                                {String(moment(_withdraw.createdAt).format("dddd")).toUpperCase()} {String(moment(_withdraw.createdAt).format("L")).toUpperCase()} às {String(moment(_withdraw.createdAt).format('HH:mm')).toUpperCase()}
                                            </TableCell>
                                            <TableCell align="center">
                                                {user ? nameUser?.firstName : clientUser.firstName} {user ? nameUser.lastName : clientUser.lastName}
                                            </TableCell>
                                            <TableCell align="center">
                                                {formatCPF((user?.cpf ?? client?.cpf))}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="Clique para abrir no WhatsApp">
                                                    <Button
                                                        component="a"
                                                        target="_blank"
                                                        href={"https://api.whatsapp.com/send/?phone=" + '55' + (user?.personalData?.phone ?? client?.phone)}
                                                        color="inherit"
                                                        sx={{ ml: 1, fontSize: 14 }}>
                                                        {formatPhone((user?.personalData?.phone ?? client?.phone))}
                                                    </Button></Tooltip>
                                            </TableCell>
                                            <TableCell align="center">
                                                {user ? "REVENDEDOR" : "CLIENTE"}
                                            </TableCell>
                                            <TableCell align="center">
                                                {ViewKindPix(user?.pix?.kind ?? client?.pix?.kind)}
                                            </TableCell>
                                            <TableCell align="center" sx={{ cursor: 'pointer' }} onClick={() => {
                                                toast("Chave copiada!", { type: 'success' })
                                                copy(user?.pix?.key ?? client?.pix?.key)
                                            }}>
                                                {user?.pix?.key ?? client?.pix?.key}
                                            </TableCell>
                                            <TableCell align="center" sx={{ minWidth: 80 }}>
                                                R$ {creditValue?.toLocaleString('pt-BR', {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                })}
                                            </TableCell>
                                            <TableCell align="center">
                                                {user?.personalData?.cep}

                                                {/* {prizeDraw?.results && prizeDraw.results.find(a => a.order === 2)?.number} */}
                                            </TableCell>
                                            <TableCell align="center">
                                                {user?.personalData?.city} {user?.personalData?.uf ? `(${user?.personalData?.uf})` : ""}
                                            </TableCell>
                                            <TableCell align="center" sx={{
                                                color: _status.color
                                            }}>
                                                {
                                                    // status === 0 ?
                                                    //     <ButtonGroup>
                                                    //         <Tooltip title="Marcar como paga">
                                                    //             <IconButton onClick={() => setCofirmWithdraw(_withdraw)}>
                                                    //                 <FiCheck />
                                                    //             </IconButton>
                                                    //         </Tooltip>
                                                    //         <Tooltip title="Cancelar solicitação">
                                                    //             <IconButton onClick={() => setCancelWithdraw(_withdraw)}>
                                                    //                 <FiX />
                                                    //             </IconButton>
                                                    //         </Tooltip>
                                                    //     </ButtonGroup> :
                                                }
                                                {_status.label}

                                            </TableCell>
                                            <TableCell align="center">
                                                {payment?.integrationId}
                                            </TableCell>
                                            <TableCell align="center">
                                                {renderIntegration(payment?.integration)}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }

                        </TableBody>
                    </TableView>
                    {total > 0 && <PaginationComponent count={total} handleClick={ListWithdraw} />}
                </Grid>
            </Grid>
            <AlertWithDraw
                handleClose={(relaod) => {
                    if (relaod) {
                        ListWithdraw();
                    }
                    setCofirmWithdraw(null)
                    setCancelWithdraw(null)
                }}
                open={!!confirmWithdraw || !!cancelWithdraw}
                withdraw={confirmWithdraw || cancelWithdraw}
                type={cancelWithdraw ? 'cancel' : confirmWithdraw ? 'confirm' : null}
            />
        </Container>
    )
}

export default WithdrawPage;