import { useEffect, useRef } from 'react';
import { io, Socket } from 'socket.io-client';
import { Utils } from 'utils';

const socketio: Socket | null = null;

const useSocket = () => {
    const socketRef = useRef<Socket>(socketio);

    useEffect(() => {
        // if (!socketRef.current.connected) {
        //     socketRef.current.connect();
        // }
        // return () => {
        //     if (socketRef.current.connected) {
        //         socketRef.current.disconnect();
        //     }
        // };
    }, []);

    return { socket: socketRef.current };
}

export default useSocket;
