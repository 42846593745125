import api from "../api"

export class CreditsService {
    static list(params?: {
        page: number,
        limit: number
    }) {
        return api.get(`/credits/withdraw`, {
            params: {
                page: params?.page ?? 1,
                limit: params?.limit ?? 10
            }
        })
    }

    static cancel(id: number) {
        return api.patch(`/credits/withdraw/cancel/${id}`)
    }

    static confirm(id: number) {
        return api.patch(`/credits/withdraw/confirm/${id}`)
    }
}