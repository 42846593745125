import React, { useEffect, useState } from 'react';
import { DialogTitle, TextField, Button, Dialog, DialogActions, DialogContent, Grid, List, ListItem, ListItemIcon, ListItemText, ListItemAvatar, Avatar, Tooltip } from '@mui/material';

import { DropzoneArea } from 'react-mui-dropzone';
import { MdFileUpload, MdOutlineUploadFile } from 'react-icons/md';
import '../../../pages/app/results/video.css'
import { PrizeDrawsService } from 'service/concurso';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { FaCheck } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';

interface ModalUploadVideoProps {
    open: boolean;
    handleClose: (updated?: boolean) => void;
}

export function ModalUploadVideo({ open, handleClose }: ModalUploadVideoProps) {

    const [loading, setLoading] = useState(false);

    const [result, setResult] = useState("")

    const [video, setVideo] = useState<File[] | null>(null)

    const [dropzoneKey, setDropzoneKey] = useState(0);

    const [identifier, setIdentifier] = useState(0)

    const handleRemoveVideo = () => {
        setResult("")
        setVideo(null);
        setDropzoneKey(prevKey => prevKey + 1); // Atualiza a chave para recriar o componente
    };

    const [list, setList] = useState<
        {
            id: number,
            name: string,
            status: "pending" | "success" | "failed",
        }[]>([])

    const onSubmit = async () => {
        if (video && video?.length > 0) {
            let name = video[0].name

            let vid = list.find((item) => item.name === name)
            if (vid?.status === "pending") {
                toast("O vídeo já está sendo upado", {
                    type: "info"
                })
                return
            }
            if (vid?.status === "success") {
                toast("O vídeo já foi upado", {
                    type: "info"
                })
                return
            }
            setLoading(true)
            let fileId = null
            const bannerUpload = await PrizeDrawsService.uploadLinkVideo({
                contentType: video[0].type ?? '',
                filename: name ?? ''
            })

            if (!bannerUpload?.data?.fileId || !bannerUpload?.data?.url) {
                toast("Não foi possível realizar upload do vídeo", {
                    type: "error"
                })
                setLoading(false)
                return
            }
            fileId = bannerUpload?.data?.fileId
            let id = identifier
            setIdentifier(id + 1)

            setList(prev => ([...prev, {
                id: id,
                name: name,
                status: "pending"
            }]))

            setLoading(false)
            handleRemoveVideo()

            let success = false
            await axios.put(bannerUpload?.data?.url, video[0], {
                headers: {
                    "Content-Type": video[0].type
                }
            })
                .then(() => {
                    success = true
                })
                .catch(() => {
                    setLoading(false);
                    setList(prev => {
                        let newPrev = [...prev]
                        let index = newPrev.findIndex((item) => item.id === id)
                        newPrev[index].status = "failed"
                        return newPrev
                    })
                    return
                })

            if (success) {
                await PrizeDrawsService.updateVideoStorage({
                    number: result,
                    fileId
                }).then(() => {
                    setList(prev => {
                        let newPrev = [...prev]
                        let index = newPrev.findIndex((item) => item.id === id)
                        newPrev[index].status = "success"
                        return newPrev
                    })
                }).catch(() => {
                    setList(prev => {
                        let newPrev = [...prev]
                        let index = newPrev.findIndex((item) => item.id === id)
                        newPrev[index].status = "failed"
                        return newPrev
                    })
                }).finally(() => {
                    setLoading(false);
                })
            }
        }
    }

    const handleCloseChange = () => {
        if (!loading) {
            handleRemoveVideo()
            handleClose();
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleCloseChange}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={list?.length > 0 ? "lg" : "md"}
        >
            <DialogTitle>
                Adicionar novo vídeo
            </DialogTitle>
            <DialogContent sx={{ minWidth: 300, display: "flex", gap: 2, flexDirection: "row" }}>
                <Grid sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
                    <DropzoneArea
                        key={dropzoneKey}
                        acceptedFiles={['video/*', 'video/mkv']}
                        filesLimit={1}
                        alertSnackbarProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                            },
                        }}
                        maxFileSize={100000000}
                        Icon={() => !video ? <MdOutlineUploadFile color={"#66666"} size={65} /> : <></>}
                        dropzoneClass="drop-class-file-att"
                        dropzoneText={!video ? "Arraste e solte o arquivo do vídeo aqui ou clique para selecionar." : ""}
                        getFileAddedMessage={(fileName) =>
                            `O arquivo ${fileName} foi adicionado com sucesso.`
                        }
                        getFileRemovedMessage={(fileName) =>
                            `O arquivo ${fileName} foi removido com sucesso.`
                        }
                        getDropRejectMessage={(rejectedFile) =>
                            `O arquivo ${rejectedFile.name} foi rejeitado. O formato do arquivo é inválido ou excede o tamanho máximo permitido de 100 MB`
                        }
                        getFileLimitExceedMessage={(filesLimit) =>
                            `Número máximo permitido de arquivo/s excedido. Somente ${filesLimit} arquivo é permitido.`
                        }
                        onChange={(file) => {
                            setVideo(file?.length === 0 ? null : file);
                        }}
                    />
                    <TextField
                        fullWidth
                        label={"Resultado da quadra"}
                        value={result}
                        onChange={(e) => {
                            if (e.target.value.length <= 4) {
                                setResult(e.target.value)
                            }
                        }}

                    />
                    <LoadingButton
                        loading={loading}
                        variant='contained'
                        fullWidth
                        disabled={result.length < 4 || !video}
                        onClick={onSubmit}
                    >
                        Adicionar vídeo
                    </LoadingButton>
                </Grid>
                {list?.length > 0 &&
                    <Grid sx={{ maxHeight: 380, overflow: 'auto' }}>
                        <List>
                            {list.sort((a, b) => b.id - a.id)?.map((item) => {
                                let icon = item.status === "pending" ? <MdFileUpload color='white' /> : item.status === "success" ? <FaCheck color='white' /> : <IoClose color='white' />
                                let color = item.status === "pending" ? "orange" : item.status === "success" ? "green" : "red"
                                let title = item.status === "pending" ? "O upload ainda está em andamento" : item.status === "success" ? "O upload foi concluído" : "Houve um erro ao realizar upload"
                                return (
                                    <Tooltip title={title} placement='right'>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar sx={{ bgcolor: color }}>
                                                    {icon}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText>{item.name}</ListItemText>
                                        </ListItem>
                                    </Tooltip>
                                )
                            })}
                        </List>
                    </Grid>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    color="inherit"
                    sx={{ backgroundColor: '#0000001A' }}
                    onClick={handleCloseChange}
                >
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
