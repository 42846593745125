import { Button, Container, Dialog, Grid, Typography } from "@mui/material"
import { KeyboardEvent, useEffect, useRef, useState } from "react"
import Ball from "assets/imgs/BOLAS RESULTADO.png"
import BG from "assets/imgs/SORTEIO BG.png"
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import useQuery from "hooks/useQuery";
import { useScript } from "hooks/useScript";
import { resolve } from "path";

export function PrizeDraw() {
    useScript("https://cdn.jsdelivr.net/npm/canvas-confetti@1.9.3/dist/confetti.browser.min.js")

    const [values, setValues] = useState<{ [key: number]: string }>({});

    const [hours, setHours] = useState("12:30");

    const query = useQuery()

    const handleKeyboard = (event: KeyboardEvent) => {
        const key = Number(event.key)
        const len = Object.keys(values).length
        if (Number.isInteger(key) && len < 4) {
            event.preventDefault()
            setValues(prev => ({
                ...prev,
                [positionInverter(len)]: key.toString(),
            }))
        }
        if (event.key === "Backspace") {
            event.preventDefault()
            let isFirst = false
            let removed = {}

            for (let value of Object.keys(values)) {
                if (!isFirst) {
                    isFirst = true
                } else {
                    removed = {
                        ...removed,
                        [value]: values[Number(value)]
                    }
                }
            }
            setValues(removed)
        }
    }

    const positionInverter = (position: number): number => {
        if (position === 0) {
            return 3
        } else if (position === 1) {
            return 2
        } else if (position === 2) {
            return 1
        } else {
            return 0
        }
    }

    function randomInRange(min: number, max: number) {
        return Math.random() * (max - min) + min;
    }

    const conffeti = () => {
        var colors = ['#EC6615', '#66666'];

        //@ts-ignore
        confetti({
            particleCount: 10,
            angle: 60,
            spread: 52,
            origin: { x: 0 },
            colors: colors
        });
        //@ts-ignore
        confetti({
            particleCount: 10,
            angle: 120,
            spread: 55,
            origin: { x: 1 },
            colors: colors
        });

        //@ts-ignore
        confetti({
            particleCount: 10,
            angle: 60,
            spread: 52,
            origin: { x: 0, y: 1 },
            colors: colors
        });
        //@ts-ignore
        confetti({
            particleCount: 10,
            angle: 120,
            spread: 55,
            origin: { x: 1, y: 1 },
            colors: colors
        });

        //@ts-ignore
        confetti({
            angle: randomInRange(55, 125),
            spread: randomInRange(50, 70),
            particleCount: 10,
            origin: { y: 0.6 },
            colors: colors
        });

        //@ts-ignore
        confetti({
            angle: randomInRange(55, 125),
            spread: randomInRange(50, 70),
            particleCount: 10,
            origin: { y: 1 },
            colors: colors
        });
        //@ts-ignore
        confetti({
            angle: randomInRange(55, 125),
            spread: randomInRange(50, 70),
            particleCount: 10,
            origin: { y: 0.3, },
            colors: colors
        });

        //@ts-ignore
        confetti({
            angle: randomInRange(55, 125),
            spread: randomInRange(50, 70),
            particleCount: 10,
            origin: { y: 1, x: 0.3 },
            colors: colors
        });
        //@ts-ignore
        confetti({
            angle: randomInRange(55, 125),
            spread: randomInRange(50, 70),
            particleCount: 10,
            origin: { y: 0.5, x: 0.3 },
            colors: colors
        });

        //@ts-ignore
        confetti({
            angle: randomInRange(55, 125),
            spread: randomInRange(50, 70),
            particleCount: 10,
            origin: { y: 1, x: 0.7 },
            colors: colors
        });
        //@ts-ignore
        confetti({
            angle: randomInRange(55, 125),
            spread: randomInRange(50, 70),
            particleCount: 10,
            origin: { y: 0.5, x: 0.7 },
            colors: colors
        });

    }

    const executeInTime = async (count: number, time: number, func: Function) => {
        func()
        for (let i = 0; i <= count; i++) {
            await awaitTime(time)
            func()
        }
    }

    const awaitTime = async (seconds: number) => {
        return new Promise(((resolve, reject) => {
            setTimeout(() => {
                resolve(true)
            }, seconds * 1000)
        }))
    }

    useEffect(() => {
        if (values[0]) {
            executeInTime(30, 0.1, conffeti)
        }
        const layout = document.getElementById("layout")
        if (layout) {
            layout?.remove()
            window.location.reload()
        }
        //@ts-ignore
        window.addEventListener("keydown", handleKeyboard)

        return () => {
            //@ts-ignore
            window.removeEventListener("keydown", handleKeyboard)
        }
    }, [values])

    useEffect(() => {
        setHours(query.get("horario") ?? "12:30")
    }, [])

    const colorNumber = (index: number): string => {
        switch (index) {
            case 0:
                return 'rgba(0, 82, 0, .9)'
            case 1:
                return 'rgba(0, 82, 0, .8)'
            case 2:
                return 'rgba(0, 82, 0, .7)'
            default:
                return '#EC6615'
        }
    }



    return (
        <Container sx={{
            padding: '100px 0px 0px 0px',
            backgroundImage: `url(${BG})`,
            height: '100vh',
            overflow: 'none !important',
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            width: '100%',
            position: 'relative'
        }}>
            <Dialog open={false}>

            </Dialog>
            <Grid xs={12} display={"flex"} direction={"row"} alignItems={"center"} justifyContent={"space-evenly"}>
                <Typography variant="h1" color={"primary"} fontSize={"60px"}>Sorteio Exclusivo</Typography>
                <Link to={"/"}>
                    <img src="https://s3.amazonaws.com/static.numerofavorito.com/logo+favorito.png" width="450px" style={{
                        marginBottom: '-20px'
                    }} />
                </Link>
            </Grid>

            <Grid mt={10}>
                <Typography variant="h1" textAlign={"center"} fontSize={"70px"} color={"#666666"}>RESULTADO</Typography>
            </Grid>

            <Grid mt={10} xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"row"} gap={"50px"}>
                {Array(4).fill(null).map((_, index) => {
                    return (
                        <Grid>
                            <Grid position={"relative"} sx={{
                                ...(values[0] && {
                                    animation: 'pulse 1.5s infinite'
                                })
                            }}>
                                <img src={Ball} width={"200px"} style={{
                                    zIndex: 10,
                                }} />
                                <input
                                    value={values[index] ?? ""}
                                    style={{
                                        zIndex: 1000,
                                        position: 'absolute',
                                        left: '50%',
                                        top: '50%',
                                        transform: 'translate(-50%,-50%)',
                                        fontSize: '120px',
                                        width: '80px',
                                        textAlign: 'center',
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        color: colorNumber(index),
                                        outline: 'none',
                                        fontWeight: 700,
                                        fontFamily: 'Poppins',
                                        ... (index === 3 && {
                                            textDecoration: 'underline',
                                            textDecorationColor: '#bab7b6'
                                        })
                                    }} />
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>

            <Grid xs={12} mt={8} mb={8} textAlign={"center"}>
                <Typography mt={2} variant="h1" color={hours !== "none" ? "primary" : "transparent"} fontSize={"60px"}>{hours} horas</Typography>
            </Grid>
        </Container>
    )
}