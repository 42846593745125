import axios, { InternalAxiosRequestConfig } from "axios";
import { Utils } from "utils";
import StorageService from "./storage";

const api = axios.create({
    baseURL: Utils.URLSBases.apisNFV
});

api.interceptors.response.use(e => e, async reject => {
    return Promise.reject(reject);
})

async function intercepter(config: InternalAxiosRequestConfig<any>) {
    const token = await StorageService.getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}

api.interceptors.request.use(intercepter);
export default api;