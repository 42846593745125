import { IChangePolicy, ICreatePolicy } from "types"
import api from "../api"

export class AuthService {
    static login(cpf: string, password: string) {
        return api.post('/users/login', {
            cpf,
            password,
            role: 100
        })
    }
    static me() {
        return api.get('/users/me')
    }
    static usersGeral() {
        return api.get('/users/general')
    }

    static createUserGeral(data: any) {
        return api.post('/users/general', data)
    }

    static sellers() {
        return api.get('/users/sellers')
    }

    static async getPolicies(id: number) {
        return api.get('/credits/commissions-policies', {
            params: {
                "general-id": id
            }
        })
    }

    static async changePolicies(data: IChangePolicy) {
        return api.put('/credits/commissions-policies', data)
    }

    static async createPolicy(data: ICreatePolicy) {
        return api.post('/credits/commissions-policies', {
            policies: [data]
        })
    }
}
